import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Web3 from "web3";

import config, { notify, UScurrencyFormatter } from "../../config/config";
import BonusMeter from "./bonus_meter";
import { withTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

function InvestForm(props) {
  const {
    t,
    loading,
    cubeEnabled,
    investEnabled,
    canWithdraw,
    fundsAvailable,
    walletConnected,
    fullData,
  } = props;
  const {
    cubeType,
    cubeAssetAllowance,
    cubeAssetBalanceGet,
    cubeAssetBalanceWei,
    cubeAssetAddress,
    cubeAssetDecimals,
    cubeHyperTokenBalanceGet,
    cubeHyperTokenBalanceWei,
    cubeHyperTokenAddress,
    cubeHyperTokenDecimals,
    cubeMinHyperTokenRatio,
    cubeMaxHyperTokenRatio,
    cubeName,
    cubeHyperTokenAllowance,
    cubeID,
    minimumUSDinvestment,
    assetInfo,
    assetDisclaimer,
    referralBonusBP,
  } = fullData;
  const nullAddress = "0x0000000000000000000000000000000000000000";
  /*
    const cubeAssetBalanceGet = props.cubeInfo[0];
    const cubeAssetAddress = props.cubeInfo[2];
    const cubeAssetDecimals = parseInt(props.cubeInfo[4])

    const cubeHyperTokenBalanceGet = props.cubeInfo[5];
    const cubeHyperTokenAddress = props.cubeInfo[7];
    const cubeHyperTokenDecimals = parseInt(props.cubeInfo[8])
    const cubeMinHyperTokenRatio = props.cubeInfo[9];
    const cubeMaxHyperTokenRatio = props.cubeInfo[10];

    const assetName = props.cubeData[0];
    const assetInformation = props.cubeData[1];
    const assetDisclaimer = props.cubeData[2];
    const cubeType = props.cubeData[3]
    const referralBonusBP = props.cubeData[5]
    const minimumUSDinvestment = props.cubeData[6]
    */

  // const amount = (cubeType !== 0 ? +props.cubeInfo[11] / 10**cubeAssetDecimals : +props.cubeInfo[11] / 1e12)

  var cubeTitleStr = t("You are investing in an Algo Trading Cube");
  if (cubeType === 1) {
    cubeTitleStr = t("You are investing in a Fixed Income Cube");
  } else if (cubeType === 2) {
    cubeTitleStr = t("You are investing in a Race Cube");
  }

  /*
    const cubeAssetAllowance = props.cubeInfo[1] / (10**cubeAssetDecimals);
    const cubeHyperTokenAllowance = props.cubeInfo[6] / (10**cubeHyperTokenDecimals);
    const cubeID = props.cubeInfo[3];
    */

  const [approveProcessA, setapproveProcessA] = useState(false);
  const [approveProcessB, setapproveProcessB] = useState(false);
  const [InvestProcess, setInvestProcess] = useState(false);
  const [assetPaymentIsApprove, setAssetPaymentIsApprove] = useState(true);
  const [hyperTokenPaymentIsApprove, setHyperTokenPaymentIsApprove] =
    useState(true);
  const [isAgree, setIsAgree] = useState(false);
  const [investAmount, setInvestAmount] = useState("");
  const [investWeiAmount, setInvestWeiAmount] = useState(0);
  const [investHyperTokenAmount, setInvestHyperTokenAmount] = useState("");
  const [investHyperTokenWeiAmount, setInvestHyperTokenWeiAmount] = useState(0);
  const [currentAssetAllowance, setCurrentAssetAllowance] = useState(0);
  const [currentHyperTokenAllowance, setCurrentHyperTokenAllowance] =
    useState(0);
  const [isAgreeError, setisAgreeError] = useState(true);

  const [investAmountUSD, setInvestAmountUSD] = useState(0);
  const [investHyperTokenAmountUSD, setInvestHyperTokenAmountUSD] = useState(0);

  const [bonusMeterSize, setBonusMeterSize] = useState(props.size);
  const [referralCode, setReferralCode] = useState("");

  const [hypEnough, setHypEnough] = useState(true);
  const [totalInvestedUSD, setTotalInvestedUSD] = useState(0);
  const [totalInvestedHypUSD, setTotalInvestedHypUSD] = useState(0);
  const [investValuesReady, setInvestValuesReady] = useState(false);

  const [hyperCube, setHyperCube] = useState(false);
  const [hyperMsg, setHyperMsg] = useState("");
  const [belowMinimum, setBelowMinimum] = useState(false);
  const [investTooMuch, setInvestTooMuch] = useState(false);
  const [allPaymentsAreApproved, setAllPaymentsAreApproved] = useState(false);

  function checkRatio(amountHypUSD, amountAssetUSD) {
    if (amountHypUSD === 0) return 0;
    else if (amountAssetUSD === 0) return 999999;
    else return amountHypUSD / amountAssetUSD;
  }

  useEffect(() => {
    if (props.size === 700) {
      setBonusMeterSize(500);
    } else {
      setBonusMeterSize(props.size * 0.8);
    }
  }, [props.size]);

  useEffect(() => {
    setCurrentAssetAllowance(cubeAssetAllowance / 10 ** cubeAssetDecimals);
    setCurrentHyperTokenAllowance(
      cubeHyperTokenAllowance / 10 ** cubeHyperTokenDecimals
    );
  }, [
    cubeAssetAllowance,
    cubeHyperTokenAllowance,
    cubeAssetDecimals,
    cubeHyperTokenDecimals,
  ]);

  useEffect(() => {
    setHypEnough(
      (cubeHyperTokenBalanceGet || 0) >= parseFloat(investHyperTokenAmount || 0)
    );
  }, [cubeHyperTokenBalanceGet, investHyperTokenAmount]);

  useEffect(() => {
    var isHyperCube = false;
    const localInvestAmountUSD = parseFloat(investAmountUSD || 0);
    let ratio = checkRatio(
      parseFloat(investHyperTokenAmountUSD || 0),
      localInvestAmountUSD
    ); // (totalInvestedHypUSD, totalInvestedUSD)
    if (
      ratio === 0 ||
      ratio < cubeMinHyperTokenRatio ||
      ratio > cubeMaxHyperTokenRatio
    ) {
      if (props.alreadyInvestedHypUSD !== 0) {
        setHyperMsg(
          "To invest, HYP-Asset ratio must be between " +
            (cubeMinHyperTokenRatio * 100).toFixed(0) +
            "% and " +
            (cubeMaxHyperTokenRatio * 100).toFixed(0) +
            "%"
        );
        isHyperCube = true;
      } else {
        setHyperMsg(
          "To make it HYPER, HYP-Asset ratio must be between " +
            (cubeMinHyperTokenRatio * 100).toFixed(0) +
            "% and " +
            (cubeMaxHyperTokenRatio * 100).toFixed(0) +
            "%"
        );
        isHyperCube = false;
      }
    } else {
      setHyperMsg("");
      isHyperCube = true;
    }
    setHyperCube(isHyperCube);
    setTotalInvestedUSD(localInvestAmountUSD + props.alreadyInvestedUSD);
    setTotalInvestedHypUSD(
      parseFloat(investHyperTokenAmountUSD || 0) + props.alreadyInvestedHypUSD
    );
    if (props.alreadyInvestedHypUSD !== 0 || isHyperCube) {
      setInvestValuesReady(
        parseFloat(investHyperTokenAmountUSD || 0) !== 0 &&
          localInvestAmountUSD !== 0
      );
    } else if (isHyperCube) {
      setInvestValuesReady(
        parseFloat(investHyperTokenAmountUSD || 0) !== 0 &&
          localInvestAmountUSD !== 0
      );
    } else {
      setInvestValuesReady(localInvestAmountUSD !== 0);
    }
    setBelowMinimum(localInvestAmountUSD < minimumUSDinvestment);
  }, [
    investAmountUSD,
    props.alreadyInvestedUSD,
    investHyperTokenAmountUSD,
    props.alreadyInvestedHypUSD,
    cubeMinHyperTokenRatio,
    cubeMaxHyperTokenRatio,
    minimumUSDinvestment,
  ]);

  useEffect(() => {
    if (investAmount > currentAssetAllowance) {
      setAssetPaymentIsApprove(false);
    } else {
      setAssetPaymentIsApprove(true);
    }
    setInvestTooMuch(investAmount > cubeAssetBalanceGet);
  }, [investAmount, cubeAssetBalanceGet, currentAssetAllowance]);

  useEffect(() => {
    let result =
      assetPaymentIsApprove &&
      hyperTokenPaymentIsApprove &&
      !approveProcessA &&
      !approveProcessB;
    console.log(
      "assetApproved",
      assetPaymentIsApprove,
      "hyperApproved",
      hyperTokenPaymentIsApprove,
      "approveA",
      approveProcessA,
      "approveB",
      approveProcessB,
      "result",
      result
    );
    setAllPaymentsAreApproved(result);
  }, [
    assetPaymentIsApprove,
    hyperTokenPaymentIsApprove,
    approveProcessA,
    approveProcessB,
  ]);

  useEffect(() => {
    if (investHyperTokenAmount > currentHyperTokenAllowance) {
      setHyperTokenPaymentIsApprove(false);
    } else {
      setHyperTokenPaymentIsApprove(true);
    }
    setInvestTooMuch(investHyperTokenAmount > cubeHyperTokenBalanceGet);
  }, [
    investHyperTokenAmount,
    cubeHyperTokenBalanceGet,
    currentHyperTokenAllowance,
  ]);

  const HandleAssetChange = async (e) => {
    setInvestWeiAmount(+e.target.value * 10 ** cubeAssetDecimals);
    setInvestAmount(e.target.value);
    await AssetUSDvalue(+e.target.value);
  };

  const HandleHyperTokenChange = async (e) => {
    setInvestHyperTokenWeiAmount(
      +e.target.value * 10 ** cubeHyperTokenDecimals
    );
    setInvestHyperTokenAmount(e.target.value);
    await HyperTokenUSDvalue(+e.target.value);
  };

  const maxAssetAmount = async () => {
    setInvestWeiAmount(cubeAssetBalanceWei);
    setInvestAmount(cubeAssetBalanceGet.toString());
    await AssetUSDvalue(cubeAssetBalanceGet);
  };

  const maxHyperTokenAmount = async () => {
    setInvestHyperTokenWeiAmount(cubeHyperTokenBalanceWei);
    setInvestHyperTokenAmount(cubeHyperTokenBalanceGet);
    await HyperTokenUSDvalue(cubeHyperTokenBalanceGet);
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const waitForTx = async (web3, transactionHash) => {
    let transactionReceipt = null;
    while (transactionReceipt == null) {
      transactionReceipt = await web3.eth.getTransactionReceipt(
        transactionHash
      );
      await sleep(3000);
    }
  };

  const approvePayment = async (e, id) => {
    if (!investValuesReady) return false;
    if ((id === 1 || id === 3 || id === 0) && approveProcessA) return false;
    if ((id === 2 || id === 3 || id === 0) && approveProcessB) return false;
    if (!isAgree) {
      setisAgreeError(true);
      return false;
    }
    if (window.ethereum) {
      if (id === 1 || id === 3 || id === 0) {
        setapproveProcessA(true);
      }
      if (id === 2 || id === 3 || id === 0) {
        setapproveProcessB(true);
      }
      let web3 = await new Web3(window.ethereum);
      var account = await web3.currentProvider.selectedAddress;
      web3.eth.defaultAccount = account;
      let abi = await config.HyperTokenABI;
      var cubeAssetContract = await new web3.eth.Contract(
        abi,
        cubeAssetAddress
      );
      var hyperTokenContract = await new web3.eth.Contract(
        abi,
        cubeHyperTokenAddress
      );
      try {
        var mainAssetAmount = investWeiAmount; // Web3.utils.toWei((investAmount || 0).toString(), 'ether');
        var hyperTokenAssetAmount = investHyperTokenWeiAmount; // Web3.utils.toWei((investHyperTokenAmount || 0).toString(), 'ether') // (parseFloat(investHyperTokenAmount) * (10 ** parseInt(cubeHyperTokenDecimals))).toString();
        var trx1;
        var trx2;
        if (
          !assetPaymentIsApprove &&
          parseFloat(investAmount || 0) > 0 &&
          (id === 0 || id === 1)
        ) {
          trx1 = await cubeAssetContract.methods
            .approve(config.FundManagerContracts[cubeType], mainAssetAmount)
            .send({ from: account });
        }
        if (
          !hyperTokenPaymentIsApprove &&
          parseFloat(investHyperTokenAmount || 0) > 0 &&
          (id === 0 || id === 2)
        ) {
          trx2 = await hyperTokenContract.methods
            .approve(
              config.FundManagerContracts[cubeType],
              hyperTokenAssetAmount
            )
            .send({ from: account });
        }
        if (trx1) {
          if (trx1.transactionHash) {
            await waitForTx(web3, trx1.transactionHash);
            setCurrentAssetAllowance("0.00");
            var newcubeAllowance = await cubeAssetContract.methods
              .allowance(account, config.FundManagerContracts[cubeType])
              .call();
            var newCubeAllow = newcubeAllowance / 10 ** cubeAssetDecimals;
            setCurrentAssetAllowance(newCubeAllow);
            // notify('Asset approval done');
            setAssetPaymentIsApprove(true);
            notify.success("Asset approval done");
          } else {
            // setapproveProcess(false);
            notify.warning("Asset approval failed");
          }
        }
        if (trx2) {
          if (trx2.transactionHash) {
            await waitForTx(web3, trx2.transactionHash);
            setCurrentHyperTokenAllowance("0.00");
            var newHyperTokenAllowance = await hyperTokenContract.methods
              .allowance(account, config.FundManagerContracts[cubeType])
              .call();
            var newHyperTokenAllow =
              newHyperTokenAllowance / 10 ** cubeHyperTokenDecimals;
            setCurrentHyperTokenAllowance(newHyperTokenAllow);
            // notify('HyperToken approval done');
            setHyperTokenPaymentIsApprove(true);
            notify.success("HyperToken approval done");
          } else {
            // setapproveProcess(false);
            notify.warning("HyperToken approval failed");
          }
        }
        if (id === 1 || id === 3 || id === 0) {
          setapproveProcessA(false);
        }
        if (id === 2 || id === 3 || id === 0) {
          setapproveProcessB(false);
        }
      } catch (e) {
        if (id === 1 || id === 3 || id === 0) {
          setapproveProcessA(false);
        }
        if (id === 2 || id === 3 || id === 0) {
          setapproveProcessB(false);
        }
        if (e.message) {
          notify.error(e.message);
        } else {
          notify.error(e.toString());
        }
      }
    } else {
      console.log("window ether not defined");
    }
  };

  const AssetUSDvalue = async (v) => {
    if (window.ethereum) {
      if (isNaN(v) || v === "" || +v < 0) {
        setInvestAmountUSD(0);
        return;
      }
      let web3 = new Web3(window.ethereum);
      let value = web3.utils.toWei(v.toString());
      if (value === 0) {
        setInvestAmountUSD(0);
        return;
      }
      let abi = config.FundManagerABIs[cubeType];
      var FundManagerContract = new web3.eth.Contract(
        abi,
        config.FundManagerContracts[cubeType]
      );
      let mainAssetAmountUSD = await FundManagerContract.methods
        .getUsdValue(cubeID, value)
        .call();
      setInvestAmountUSD(web3.utils.fromWei(mainAssetAmountUSD));
    }
  };

  const HyperTokenUSDvalue = async (v) => {
    if (window.ethereum) {
      if (isNaN(v) || v === "" || +v < 0) {
        setInvestHyperTokenAmountUSD(0);
        return;
      }
      let web3 = new Web3(window.ethereum);
      let value = +v;
      if (value === 0) {
        setInvestHyperTokenAmountUSD(0);
        return;
      }
      let abi = config.FundManagerABIs[cubeType];
      var FundManagerContract = new web3.eth.Contract(
        abi,
        config.FundManagerContracts[cubeType]
      );
      var hyperTokenPrice = await FundManagerContract.methods
        .getHyperTokenPrice()
        .call();
      let hyperTokenAmountUSD = (value * hyperTokenPrice) / 1e18;
      setInvestHyperTokenAmountUSD(hyperTokenAmountUSD);
    }
  };

  const InvestNow = async (e) => {
    if (
      InvestProcess ||
      approveProcessA ||
      approveProcessB ||
      !investValuesReady
    )
      return;
    if (!isAgree) {
      setisAgreeError(true);
      return false;
    }
    if (parseFloat(investAmount) === 0) {
      notify.warning("Asset amount cannot be zero");
      return false;
    }
    if (window.ethereum) {
      setInvestProcess(true);
      let web3 = await new Web3(window.ethereum);
      var account = await web3.currentProvider.selectedAddress;
      web3.eth.defaultAccount = account;
      let abi = await config.FundManagerABIs[cubeType];
      var FundManagerContract = await new web3.eth.Contract(
        abi,
        config.FundManagerContracts[cubeType]
      );
      try {
        var mainAssetAmount = investWeiAmount; // Web3.utils.toWei(investAmount.toString(), 'ether') // (parseInt(investAmount) * (10 ** parseInt(cubeTokenDecimal))).toString();
        var hyperTokenAmount = investHyperTokenWeiAmount; // Web3.utils.toWei((investHyperTokenAmount || 0).toString(), 'ether') // (parseInt(investHyperTokenAmount) * (10 ** parseInt(cubeHyperTokenDecimals))).toString();
        var hyperTokenPrice = await FundManagerContract.methods
          .getHyperTokenPrice()
          .call();
        var hyperTokenAmountUSD = (hyperTokenAmount * hyperTokenPrice) / 1e18;
        var mainAssetAmountUSD = await FundManagerContract.methods
          .getUsdValue(cubeID, mainAssetAmount)
          .call();
        let ratio = checkRatio(hyperTokenAmountUSD, mainAssetAmountUSD);
        if (
          ratio !== 0 &&
          (ratio < cubeMinHyperTokenRatio || ratio > cubeMaxHyperTokenRatio)
        ) {
          notify.warning(
            "Investment in HyperToken does not match the min/max ratio"
          );
          setInvestProcess(false);
          return false;
        }
        var trx = await FundManagerContract.methods
          .invest(
            cubeID,
            mainAssetAmount,
            hyperTokenAmount,
            referralCode || nullAddress
          )
          .send({ from: account });
        if (trx.transactionHash) {
          notify.success("Investment Successful!!");
          await waitForTx(web3, trx.transactionHash);
          setInvestProcess(false);
          // window.location.reload();
          window.location.href = "#/account";
        } else {
          setInvestProcess(false);
          notify.warning("Investment Failed!!");
        }
      } catch (e) {
        setInvestProcess(false);
        if (e.message) {
          notify.error(e.message);
        } else {
          notify.error(e.toString());
        }
      }
    } else {
      console.log("window ether not defined");
    }
  };

  const clickAgree = () => {
    setIsAgree(!isAgree);
    setisAgreeError(isAgree);
  };
  /*
    const allPaymentsAreApproved = () => {
        return assetPaymentIsApprove && hyperTokenPaymentIsApprove && !approveProcessA && !approveProcessB
    }
    */

  return !walletConnected ? (
    <div className="tabs-content__inner">
      <div className="row text-center">
        <h3 className="text-white">Please connect Web3 wallet</h3>
      </div>
    </div>
  ) : loading ? (
    <div className="tabs-content__inner">
      <div className="row text-center">
        <h3 className="text-white">Loading data...</h3>
      </div>
    </div>
  ) : (
    <div className="tabs-content__inner">
      {cubeEnabled && investEnabled ? (
        <div className="row text-center">
          <small className="text-white">{cubeTitleStr}</small>

          <small className="text-white">{t("ASSET INFO")}</small>

          <small className="small-disclaimer">{assetInfo}</small>
          <small className="text-white">{t("DISCLAIMER")}</small>
          <small className="small-disclaimer">{assetDisclaimer}</small>
          <div className="text-white">
            {/*<input type="checkbox" checked={isAgree} onChange={e => clickAgree(e)} /> {t("AGREE")}*/}
            <i
              className={"fa" + (isAgree ? " fa-check" : " fa-circle")}
              style={
                isAgree
                  ? {
                      color: "white",
                      border: "1px solid white",
                      borderRadius: "50%",
                    }
                  : {
                      color: "rgba(var(--hypdex-white-rgb), 0.2)",
                      border: "1px solid rgba(var(--hypdex-white-rgb), 0.2)",
                      borderRadius: "50%",
                    }
              }
              onClick={(e) => clickAgree(e)}
            ></i>{" "}
            {t("AGREE")}
          </div>
          {/*<Alert_popup isOpen={!isAgree} message={['prova1', 'prova2']} />*/}
          <hr className="m-t-20" />
          <div className="col-xl-6">
            <div className="col-md-12 m-t-20">
              <h3 className="text-white">{cubeName || "..."}</h3>
              <label>
                {t("Current Allowance")}: {currentAssetAllowance || "0.0000"}
              </label>
              <div className="comment-form__input-box">
                <input
                  type="text"
                  style={{ height: "38px" }}
                  className="text-center form-control"
                  placeholder={t("enter amount to invest")}
                  name="input_amount"
                  onChange={(e) => HandleAssetChange(e)}
                  value={investAmount}
                />
              </div>
              <p>
                {t("Available")}{" "}
                {cubeAssetBalanceGet
                  ? UScurrencyFormatter(cubeAssetBalanceGet, 6)
                  : "0.0000"}
                {
                  /*cubeAssetBalanceGet <= 100000 &&*/ cubeAssetBalanceGet >
                  0 ? (
                    <>
                      <span> | </span>
                      <Link
                        className="use-max"
                        to="#"
                        onClick={() => maxAssetAmount()}
                      >
                        {t("use max")}
                      </Link>
                    </>
                  ) : (
                    ""
                  )
                }
              </p>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="col-md-12 m-t-20">
              <h3 className="text-white">HYP</h3>
              <label>
                {t("Current Allowance")}:{" "}
                {currentHyperTokenAllowance || "0.0000"}
              </label>
              <div className="comment-form__input-box">
                <input
                  type="text"
                  style={{ height: "38px" }}
                  className="text-center form-control"
                  placeholder={t("enter amount to invest")}
                  name="input_amount"
                  onChange={(e) => HandleHyperTokenChange(e)}
                  value={investHyperTokenAmount}
                />
              </div>
              {hypEnough ? (
                <p>
                  {t("Available")}{" "}
                  {cubeHyperTokenBalanceGet
                    ? UScurrencyFormatter(cubeHyperTokenBalanceGet, 2)
                    : "0.0000"}
                  {
                    /*cubeHyperTokenBalanceGet <= 100000 &&*/ cubeHyperTokenBalanceGet >
                    0 ? (
                      <>
                        <span> | </span>
                        <Link
                          className="use-max"
                          to="#"
                          onClick={() => maxHyperTokenAmount()}
                        >
                          {t("use max")}
                        </Link>
                      </>
                    ) : (
                      ""
                    )
                  }
                </p>
              ) : (
                <Link className="use-max" to="/swap">
                  <p>{t("Buy more")} HYP!</p>
                </Link>
              )}
            </div>
          </div>
          <div className="row m-t-20">
            {hyperCube ? (
              <>
                <h3 style={{ textAlign: "center", color: "white" }}>
                  {t("This is now a HyperCube!")}
                </h3>
                <p>{hyperMsg}</p>
              </>
            ) : (
              <>
                <h3 style={{ textAlign: "center", color: "white" }}>
                  {t("This is a standard Cube")}
                </h3>
                <p>{hyperMsg}</p>
              </>
            )}
            <div className="col-md-6 col-xs-12">
              <h3
                className="top m-t-20"
                style={{ textAlign: "center", color: "white" }}
              >
                {t("USD Value")}:{" "}
                <span style={{ color: "#ffa0f8" }}>
                  {investAmountUSD
                    ? parseFloat(investAmountUSD).toFixed(2)
                    : "0.00"}{" "}
                  $
                </span>
                <br />
                <small className="f-s-06">
                  {t("Total")}:{" "}
                  {totalInvestedUSD
                    ? parseFloat(totalInvestedUSD).toFixed(2)
                    : "0.00"}{" "}
                  $
                </small>
              </h3>
            </div>
            <div className="col-md-6 col-xs-12">
              <h3
                className="top m-t-20"
                style={{ textAlign: "center", color: "white" }}
              >
                {t("USD Value")}:{" "}
                <span style={{ color: "#ffa0f8" }}>
                  {investHyperTokenAmountUSD
                    ? parseFloat(investHyperTokenAmountUSD).toFixed(2)
                    : "0.00"}{" "}
                  $
                </span>
                <br />
                <small className="f-s-06">
                  {t("Total")}:{" "}
                  {totalInvestedHypUSD
                    ? parseFloat(totalInvestedHypUSD).toFixed(2)
                    : "0.00"}{" "}
                  $
                </small>
              </h3>
            </div>
            {hyperCube ? (
              <BonusMeter
                investAmountUSD={investAmountUSD}
                size={bonusMeterSize}
                account={props.account}
                referralBonusBP={referralBonusBP}
                referralCode={referralCode}
                setReferralCode={setReferralCode}
                referralUsed={props.referralUsed}
                alreadyInvestedUSD={props.alreadyInvestedUSD}
              />
            ) : (
              <p>{t("Make it HYPER to unlock Bonus & Rewards")}</p>
            )}
          </div>
          {isAgreeError ? (
            <div className="col-md-12 m-t-1e">
              <h2 style={{ color: "white" }}>
                {t(
                  "Attention: You must agree to the terms and conditions before investing"
                )}
              </h2>
            </div>
          ) : canWithdraw && !fundsAvailable ? (
            <div className="col-md-12 m-t-1e">
              <h2 style={{ color: "white" }}>
                {t(
                  "You have an ongoing unlocking process. Please wait for it to be completed before investing."
                )}
              </h2>
            </div>
          ) : belowMinimum ? (
            <div className="col-md-12 m-t-1e">
              <h2 style={{ color: "white" }}>
                {t("The minimum investment is")}{" "}
                {minimumUSDinvestment.toFixed(2)} $
              </h2>
            </div>
          ) : (
            <>
              <div className="col-md-2 m-t-1e"></div>
              <div className="col-md-4 m-t-1e">
                <Link
                  to="#"
                  className={
                    "thm-btn comment-form__btn" +
                    (approveProcessA ||
                    approveProcessB ||
                    isAgreeError ||
                    !investValuesReady
                      ? " disabled"
                      : "")
                  }
                  onClick={(e) => approvePayment(e, 0)}
                >
                  {approveProcessA || approveProcessB ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">{t("Wait...")}</span>
                    </Spinner>
                  ) : (
                    t("Approve")
                  )}
                </Link>
              </div>

              <div className="col-md-4 m-t-1e">
                <Link
                  to="#"
                  className={
                    "thm-btn comment-form__btn" +
                    (InvestProcess ||
                    isAgreeError ||
                    !investValuesReady ||
                    !allPaymentsAreApproved ||
                    investTooMuch
                      ? " disabled"
                      : "")
                  }
                  onClick={(e) => InvestNow(e)}
                >
                  {InvestProcess ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">{t("Wait...")}</span>
                    </Spinner>
                  ) : (
                    t("Invest")
                  )}
                </Link>
              </div>
              <div className="col-md-2 m-t-1e"></div>
            </>
          )}
        </div>
      ) : (
        <div className="row text-center">
          {!investEnabled && <h3 className="text-white">Expired</h3>}
          {!cubeEnabled && <h3 className="text-white">Cube not active</h3>}
        </div>
      )}
    </div>
  );
}

export default withTranslation()(InvestForm);
