import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import config, { notify } from "../../config/config";
import dateFormat from "dateformat";
import { withTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { ethers } from "ethers";

function WithdrawForm(props) {
  const {
    t,
    loading,
    canWithdraw,
    fundsAvailable,
    investEnabled,
    alreadyUnlocked,
    expirationTimestamp,
    walletConnected,
    cubeEnabled,
    fullData,
    sharePrice,
  } = props;
  const {
    cubeName,
    cubeAssetDecimals,
    assetInfo,
    assetDisclaimer,
    cubeType,
    guaranteedBP,
    cubeID,
    userInfoAmount,
    InvestmentDetails,
  } = fullData;

  /*
    const cubeAssetDecimals = parseInt(props.cubeInfo[4])
    const assetName = props.cubeData[0];
    const assetInformation = props.cubeData[1];
    const assetDisclaimer = props.cubeData[2];
    const cubeType = props.cubeData[3]
    const guaranteedBP = props.cubeData[4]
    // const referralBonusBP = props.cubeData[5]
    const cubeID = props.cubeInfo[3];
    */

  var cubeTitleStr = t("You are withdrawing from an Algo Trading Cube");
  if (cubeType === 1) {
    cubeTitleStr = t("You are withdrawing from a Fixed Income Cube");
  } else if (cubeType === 2) {
    cubeTitleStr = t("You are withdrawing from a Race Cube");
  }

  const [isAgree, setIsAgree] = useState(false);
  const [isAgreeError, setisAgreeError] = useState(true);

  const [unlockAmount, setUnlockAmount] = useState("");
  const [unlockBtn, setUnlockBtn] = useState(false);
  const [earlyUnlockAmount, setEarlyUnlockAmount] = useState("");
  const [earlyUnlockBtn, setEarlyUnlockBtn] = useState(false);
  // const [selectedUnlockPerc, setSelectedUnlockPerc] = useState({ perc: 0, pos: '0' })
  // const [selectedEarlyUnlockPerc, setSelectedEarlyUnlockPerc] = useState({ perc: 0, pos: '0' })
  const [unlockValueReady, setUnlockValueReady] = useState(false);
  const [earlyUnlockValueReady, setEarlyUnlockValueReady] = useState(false);

  // const [hyperCube, setHyperCube] = useState(false)
  // const [hyperMsg, setHyperMsg] = useState('')
  const [pendingWithdrawal, setPendingWithdrawal] = useState(false);
  const [amount, setAmount] = useState(
    cubeType !== 0 ? +props.userInfoAmount / 1e18 : +props.userInfoAmount / 1e12
  );
  const [withdrawableAmount, setWithdrawableAmount] = useState(0);
  const [amountInAsset, setAmountInAsset] = useState(0);
  const [assetEquity, setAssetEquity] = useState(0);

  useEffect(() => {
    setUnlockAmount("");
    setEarlyUnlockAmount("");
    // setSelectedUnlockPerc({ perc: 0, pos: '0' })
    // setSelectedEarlyUnlockPerc({ perc: 0, pos: '0' })
  }, []);

  useEffect(() => {
    let localAmount =
      cubeType !== 0 ? +userInfoAmount / 1e18 : +userInfoAmount / 1e12;
    setAmount(localAmount);
  }, [userInfoAmount, cubeType]);

  useEffect(() => {
    let localAmount =
      amount +
      (InvestmentDetails.AssetUnlockedAmount || 0) +
      (InvestmentDetails.AssetEarlyUnlockedAmount || 0) +
      (InvestmentDetails.UnlockedAssetProfit || 0) +
      (InvestmentDetails.EarlyUnlockedAssetProfit || 0);
    setWithdrawableAmount(localAmount);
    setAssetEquity(+InvestmentDetails.EquityInAsset);
    if (cubeType === 1) {
      setUnlockAmount(amount.toString());
      setUnlockValueReady(amount !== 0);
    }
  }, [amount, InvestmentDetails]);

  useEffect(() => {
    let localValue = amount * sharePrice;
    console.log("setAmountInAsset", amount, sharePrice, localValue);
    setAmountInAsset(localValue);
  }, [amount, sharePrice]);

  /*
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    */

  /*
    const waitForTx = async (web3, transactionHash) => {
        let transactionReceipt = null
        while (transactionReceipt == null) {
            transactionReceipt = await web3.eth.getTransactionReceipt(transactionHash);
            await sleep(3000)
        }
    }
    */

  const clickAgree = () => {
    setIsAgree(!isAgree);
    setisAgreeError(isAgree);
  };

  /*
    const setUnlockAmountPerc = async (perc, percPos) => {
        let value = (amount * perc / 100).toFixed(props.assetDecimalsToShow)
        if (perc === 0) {
            setUnlockAmount('')
            setUnlockValueReady(false)
        } else {
            setUnlockAmount(value)
            setUnlockValueReady(true)
        }
        // setSelectedUnlockPerc({ perc: perc, pos: percPos + '%' })
    }
    */

  /*
    const setEarlyUnlockAmountPerc = async (perc, percPos) => {
        let value = (amount * perc / 100).toFixed(props.assetDecimalsToShow)
        if (perc === 0) {
            setEarlyUnlockAmount('')
            setEarlyUnlockValueReady(false)
        } else {
            setEarlyUnlockAmount(value)
            setEarlyUnlockValueReady(true)
        }
        // setSelectedEarlyUnlockPerc({ perc: perc, pos: percPos + '%' })
    }
    */

  const HandleUnlockChange = async (e) => {
    let value = parseFloat(e.target.value || "0");
    setUnlockAmount(e.target.value);
    setUnlockValueReady(value !== 0);
    // setSelectedUnlockPerc({ perc: 0, pos: '0%' })
  };

  const HandleEarlyUnlockChange = async (e) => {
    let value = parseFloat(e.target.value || "0");
    setEarlyUnlockAmount(e.target.value);
    setEarlyUnlockValueReady(value !== 0);
    // setSelectedEarlyUnlockPerc({ perc: 0, pos: '0%' })
  };

  const UnlockNow = async (v) => {
    if (isAgreeError) return;
    const { userInfoAmount, alreadyUnlocked } = props;
    setUnlockBtn(true);
    if (window.ethereum) {
      var localUnlockAmount = +unlockAmount;
      if (alreadyUnlocked !== 0) {
        localUnlockAmount = 0;
      }
      var amountToUnlock =
        cubeType !== 0
          ? localUnlockAmount * 10 ** cubeAssetDecimals
          : localUnlockAmount * 1e12;
      if (cubeType === 1) {
        amountToUnlock = userInfoAmount;
      }
      // console.log(amountToUnlock, amountToUnlock.toLocaleString('fullwide', {useGrouping:false}))
      let web3 = await new Web3(window.ethereum);
      var account = await web3.currentProvider.selectedAddress;
      web3.eth.defaultAccount = account;
      let abi = await config.FundManagerABIs[cubeType];
      var FundManagerContract = await new web3.eth.Contract(
        abi,
        config.FundManagerContracts[cubeType]
      );
      try {
        var trx =
          cubeType !== 0
            ? await FundManagerContract.methods
                .unlockAmount(
                  cubeID,
                  amountToUnlock.toLocaleString("fullwide", {
                    useGrouping: false,
                  })
                )
                .send({ from: account })
            : await FundManagerContract.methods
                .unlockShares(
                  cubeID,
                  amountToUnlock.toLocaleString("fullwide", {
                    useGrouping: false,
                  })
                )
                .send({ from: account });
        if (trx.transactionHash) {
          notify.success("Transaction successful!!");
        } else {
          notify.warning("Transaction Failed!!");
        }
        window.location.reload();
      } catch (e) {
        if (e.message) {
          notify.error(e.message);
        } else {
          notify.error(e.toString());
        }
        setUnlockBtn(false);
      }
    } else {
      setUnlockBtn(false);
      console.log("window ether not defined");
    }
  };

  const earlyUnlockNow = async () => {
    if (isAgreeError) return;
    setEarlyUnlockBtn(true);
    if (window.ethereum) {
      let localUnlockAmount = +unlockAmount;
      let amountToUnlock =
        cubeType !== 0
          ? localUnlockAmount * 10 ** cubeAssetDecimals
          : localUnlockAmount * 1e12;
      let web3 = await new Web3(window.ethereum);
      var account = await web3.currentProvider.selectedAddress;
      web3.eth.defaultAccount = account;
      let abi = await config.FundManagerABIs[cubeType];
      var FundManagerContract = await new web3.eth.Contract(
        abi,
        config.FundManagerContracts[cubeType]
      );
      try {
        var trx;
        if (cubeType === 0) {
          console.log(
            "amount",
            cubeID,
            ethers.utils.parseUnits(earlyUnlockAmount, 12).toString(),
            amountToUnlock.toLocaleString("fullwide", { useGrouping: false })
          );
          trx = await FundManagerContract.methods
            .earlyUnlockShares(
              cubeID,
              ethers.utils.parseUnits(earlyUnlockAmount, 12).toString()
            )
            .send({ from: account });
        } else if (cubeType === 1) {
          trx = await FundManagerContract.methods
            .earlyUnlockAmount(
              cubeID,
              ethers.utils.parseUnits(earlyUnlockAmount, 12).toString()
            )
            .send({ from: account });
        } else if (cubeType === 2) {
          trx = await FundManagerContract.methods
            .earlyUnlockAmount(cubeID)
            .send({ from: account });
        }
        if (trx.transactionHash) {
          notify.success("Transaction successful!!");
        } else {
          notify.warning("Transaction Failed!!");
        }
        window.location.reload();
      } catch (e) {
        if (e.message) {
          notify.error(e.message);
        } else {
          notify.error(e.toString());
        }
        setEarlyUnlockBtn(false);
      }
    } else {
      setEarlyUnlockBtn(false);
      console.log("window ether not defined");
    }
  };

  const withdrawBtn = async () => {
    if (props.account) {
      setPendingWithdrawal(true);
      let web3 = await new Web3(window.ethereum);
      var account = await web3.currentProvider.selectedAddress;
      web3.eth.defaultAccount = account;
      let abi = await config.FundManagerABIs[cubeType];
      var FundManagerContract = await new web3.eth.Contract(
        abi,
        config.FundManagerContracts[cubeType]
      );
      var withdraw = await FundManagerContract.methods
        .withdraw(cubeID)
        .send({ from: account });
      notify.success(withdraw);
      setPendingWithdrawal(false);
      window.location.reload();
    } else {
      console.log("window ether not defined");
    }
  };

  return !walletConnected ? (
    <div className="tabs-content__inner">
      <div className="row text-center">
        <h3 className="text-white">Please connect Web3 wallet</h3>
      </div>
    </div>
  ) : loading ? (
    <div className="tabs-content__inner">
      <div className="row text-center">
        <h3 className="text-white">Loading data...</h3>
      </div>
    </div>
  ) : amount !== 0 || true ? (
    <div className="tabs-content__inner">
      <div className="row text-center">
        <small className="text-white">{cubeTitleStr}</small>

        <small className="text-white">{t("ASSET INFO")}</small>

        <small className="small-disclaimer">{assetInfo}</small>
        <small className="text-white">{t("DISCLAIMER")}</small>
        <small className="small-disclaimer">{assetDisclaimer}</small>
        <div className="text-white">
          {/*<input type="checkbox" checked={isAgree} onChange={e => clickAgree(e)} /> {t("AGREE")}*/}
          <i
            className={"fa" + (isAgree ? " fa-check" : " fa-circle")}
            style={
              isAgree
                ? {
                    color: "white",
                    border: "1px solid white",
                    borderRadius: "50%",
                  }
                : {
                    color: "rgba(var(--hypdex-white-rgb), 0.2)",
                    border: "1px solid rgba(var(--hypdex-white-rgb), 0.2)",
                    borderRadius: "50%",
                  }
            }
            onClick={(e) => clickAgree(e)}
          ></i>{" "}
          {t("AGREE")}
        </div>
        <hr className="m-t-20" />
        <div style={{ height: "40px" }} />
        {cubeEnabled && amount !== 0 && investEnabled && cubeType !== 1 && (
          <div className="row">
            <div className="col-xl-8 col-lg-8">
              <div className="services-one__top-left">
                <div className="section-title text-left">
                  <h2 className="section-title__title">{t("Early Unlock")}</h2>
                  <p style={{ lineHeight: "1.1em" }}>
                    {t(
                      "WARNING: If you proceed with the early unlock a fee will be charged"
                    )}{" "}
                    ({((10000 - guaranteedBP) / 100).toFixed(0)}%).
                    <br />
                    {t(
                      "Your funds will be available for withdrawal within 24 hours."
                    )}
                    <br />
                    {t("The operation is not reversible.")}
                  </p>
                  {amount === 0 ? (
                    <div>
                      <label>{t("No amount available.")}</label>
                    </div>
                  ) : cubeType === 2 ? (
                    <>
                      <label>{t("You will unlock the whole amount.")}</label>
                    </>
                  ) : (
                    <>
                      <input
                        className="text-center form-control"
                        placeholder={
                          cubeType === 0
                            ? t("enter shares to unlock...")
                            : t("enter amount to unlock...")
                        }
                        type="text"
                        onChange={(e) => HandleEarlyUnlockChange(e)}
                        value={earlyUnlockAmount}
                      />
                    </>
                  )}
                  <p>
                    {cubeType === 0 && (
                      <>
                        {t("Available")}:{" "}
                        {!amount ? "0.0000" : amount.toFixed(12)} shares
                        <br />({t("Available")}:{" "}
                        {!amountInAsset
                          ? "0.0000"
                          : amountInAsset.toFixed(
                              props.assetDecimalsToShow
                            )}{" "}
                        {cubeName})
                      </>
                    )}
                    {cubeType === 1 && (
                      <>
                        {t("Available")}:{" "}
                        {!amount
                          ? "0.0000"
                          : amount.toFixed(props.assetDecimalsToShow)}{" "}
                        {cubeName}
                      </>
                    )}
                    {cubeType === 2 && (
                      <>
                        {t("Available")}:{" "}
                        {!amount
                          ? "0.0000"
                          : ((amount * guaranteedBP) / 10000).toFixed(
                              props.assetDecimalsToShow
                            )}{" "}
                        {cubeName}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 align-self-center">
              {isAgreeError ? (
                <h2 style={{ color: "white" }}>
                  {t(
                    "Attention: You must agree to the terms and conditions before unlocking"
                  )}
                </h2>
              ) : (
                <Link
                  to="#"
                  className={
                    "thm-btn comment-form__btn" +
                    (earlyUnlockBtn ||
                    isAgreeError ||
                    (!earlyUnlockValueReady && cubeType !== 2)
                      ? " disabled"
                      : "")
                  }
                  onClick={(e) => earlyUnlockNow()}
                >
                  {earlyUnlockBtn ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">{t("Wait...")}</span>
                    </Spinner>
                  ) : (
                    t("Early Unlock")
                  )}
                </Link>
              )}
            </div>
            <br />
          </div>
        )}
        {cubeType === 1 && amount !== 0 && alreadyUnlocked === 0 && (
          <div className="row">
            <div className="col-xl-8 col-lg-8">
              <div className="services-one__top-left">
                <div className="section-title text-left">
                  <h2 className="section-title__title">{t("Unlock")}</h2>
                  <p style={{ lineHeight: "1.1em" }}>
                    {t(
                      "Your funds will be available for unlocking and withdrawal at Cube expiration,"
                    )}
                    <br /> {t("after")} (
                    {dateFormat(expirationTimestamp * 1000, "mmm d, yyyy")}).
                  </p>
                  {
                    /* cubeType !== 2 && alreadyUnlocked !== 0 ?
                                            <p>{t("You already unlocked")} {alreadyUnlocked} { cubeType !== 0 ? cubeName : 'SHARES' } . {t("Click to lock that amount back.")}</p>
                                            : */
                    cubeType === 2 || cubeType === 1 ? (
                      <>
                        <label>{t("You will unlock the whole amount")}</label>
                      </>
                    ) : (
                      <>
                        <input
                          className="text-center form-control"
                          placeholder={
                            cubeType === 0
                              ? t("enter shares to unlock...")
                              : t("enter amount to unlock...")
                          }
                          type="text"
                          onChange={(e) => HandleUnlockChange(e)}
                          value={unlockAmount}
                        />
                      </>
                    )
                  }
                  <p>
                    {t("Available")}:{" "}
                    {!assetEquity
                      ? "0.0000"
                      : assetEquity.toFixed(props.assetDecimalsToShow)}{" "}
                    {cubeName}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 align-self-center">
              {!investEnabled && (
                <Link
                  to="#"
                  className={
                    "thm-btn comment-form__btn" +
                    (unlockBtn ||
                    isAgreeError ||
                    (!unlockValueReady && alreadyUnlocked === 0)
                      ? " disabled"
                      : "")
                  }
                  onClick={(e) => UnlockNow()}
                >
                  {unlockBtn ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">t('Wait...')</span>
                    </Spinner>
                  ) : alreadyUnlocked !== 0 ? (
                    "Lock"
                  ) : (
                    "Unlock"
                  )}
                </Link>
              )}
            </div>
            <br />
          </div>
        )}
        <div className="row">
          <div className="col-xl-8 col-lg-8">
            <div className="services-one__top-left">
              <div className="section-title text-left">
                <h2 className="section-title__title">{t("Withdraw")}</h2>
                <>
                  {canWithdraw && withdrawableAmount !== 0 ? (
                    fundsAvailable &&
                    InvestmentDetails.fundsAvailableOnSmartContract ? (
                      <p>
                        {t("You can withdraw your available funds.")}
                        <br />
                        {t("NOTE: the referral bonus will be removed.")}
                      </p>
                    ) : pendingWithdrawal ? (
                      <p>
                        {t(
                          "A withdrawal is still pending. Please wait for network confirmation."
                        )}
                      </p>
                    ) : (
                      <p>
                        {t(
                          "A withdrawal is preparing. Your funds will be available within 24 hours."
                        )}
                      </p>
                    )
                  ) : (
                    <p>
                      {t("No unlocked funds are available for withdrawal.")}
                    </p>
                  )}
                </>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 align-self-center">
            {canWithdraw &&
            fundsAvailable &&
            withdrawableAmount !== 0 &&
            InvestmentDetails.fundsAvailableOnSmartContract ? (
              <>
                {isAgreeError ? (
                  <h2 style={{ color: "white" }}>
                    {t(
                      "Attention: You must agree to the terms and conditions before withdrawing"
                    )}
                  </h2>
                ) : (
                  <Link
                    to="#"
                    className={
                      "thm-btn comment-form__btn" +
                      (pendingWithdrawal ? " disabled" : "")
                    }
                    onClick={(e) => withdrawBtn()}
                  >
                    {pendingWithdrawal /*t('Wait...')*/ ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">{t("Wait...")}</span>
                      </Spinner>
                    ) : (
                      t("Withdraw")
                    )}
                  </Link>
                )}
              </>
            ) : (
              canWithdraw &&
              withdrawableAmount !== 0 && (
                <>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">
                      {t("Waiting for funds...")}
                    </span>
                  </Spinner>
                  <span>{t("Waiting for funds...")}</span>
                </>
              )
            )}
          </div>
          <br />
        </div>
      </div>
    </div>
  ) : (
    <div className="tabs-content__inner">
      <div className="row text-center">
        <h3 className="text-white">Zero Asset</h3>
      </div>
    </div>
  );
}

export default withTranslation()(WithdrawForm);
