import React from "react";
import { connect } from "react-redux";

import { Link } from "gatsby";

import InvestForm from "./invest_form";
import WithdrawForm from "./withdraw_form";

import Web3 from "web3";
import config, { timeFormatter } from "../../config/config";
import {
  cubes,
  defaultDisclaimer,
  defaultInfo,
  tokens,
} from "../../services/constants";

import ApexCharts from "apexcharts";
import transakSDK from "@transak/transak-sdk";

import "../../assets/hypdex.css";
import "../../assets/hypdex-responsive.css";
import { jQueryFunc, loadPage } from "../../assets/js/hypdex.js";
import { withTranslation } from "react-i18next";
import { hotjar } from "react-hotjar";

const getCubeId = () => {
  if (typeof window === "object") {
    const path = window.location.href.split("/");
    let cubeId = parseInt(path[5]);
    return cubeId;
  }
};

const getCubeIndex = () => {
  if (typeof window === "object") {
    const path = window.location.href.split("/");
    let cubeIndex = parseInt(path[6]);
    return cubeIndex;
  }
};
class Trading extends React.PureComponent {
  constructor(props) {
    super(props);
    this.tick = this.tick.bind(this);
    this.state = {
      areaChartDuration: "m",
      areaChart: [],
      change: 0,
      InvestmentDetails: [],
      cubeAssetBalanceGet: 0,
      cubeAssetBalanceWei: 0,
      cubeAssetAllowance: 0,
      cubeAssetAddress: "",
      cubeAssetDecimals: 0,
      earlyUnlockBtn: false,
      UnlockBtn: false,
      userInfoAmount: 0,
      cubeName: "",
      assetInfo: "",
      assetDisclaimer: "",
      cubeType: 0,
      cubeDuration: 0,
      cubeExpirationTimestamp: 0,
      cubeExpirationDate: new Date(),
      cubeHyperTokenBalanceGet: 0,
      cubeHyperTokenBalanceWei: 0,
      cubeHyperTokenAllowance: 0,
      cubeHyperTokenTokenAddress: "",
      cubeHyperTokenDecimals: 0,
      minHyperTokenRatio: 0.1,
      maxHyperTokenRatio: 1,
      walletAddress:
        typeof localStorage == "object" ? localStorage.walletAddress : "",
      windowSize: 700,
      guaranteedBP: 0,
      referralBonusBP: 0,
      currentAssetValue: 0,
      assetDecimalsToShow: 2,
      referralUsed: false,
      pendigWithdrawal: false,
      binanceSymbol: "",
      racePrice: 0,
      priceStreamStatus: -1,
      investEnabled: false,
      loading: true,
      openAlert: false,
      cubeStopPrice: 0,
      cubeTargetPrice: 0,
      cubeBorderPrice: 0,
      defaultTab: 0,
      cubeEnabled: false,
      cubeLastPrice: 0,
      walletConnected: false,
      secondsToNextRefresh: 60,
      minimumUSDinvestment: 0,
      cubeID: -1,
      tradingPageTitle: "TRADING",
    };
  }

  // interval = -1
  priceStream;

  componentDidMount() {
    jQueryFunc();
    //loadPage(this.props.location.pathname + this.props.location.search);
    window.scrollTo(0, 0);
    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      var account = web3.currentProvider.selectedAddress;
      if (!account) {
        localStorage.setItem("walletAddress", account);
        this.setState({ walletAddress: account });
      } else {
        setTimeout(() => {
          this.myAssetDetail();
        }, 1000);
        /*
                this.interval =  setInterval(() => {
                    this.myAssetDetail();
                }, 60000);
                */
        this.timer = setInterval(this.tick, 1000);
      }
    } else {
      localStorage.setItem("walletAddress", "");
      this.setState({ walletAddress: "", openAlert: true });
    }

    let findIndex = getCubeIndex();

    var cubesJson = cubes[findIndex];

    this.setState({
      assetInfo: cubesJson.info || defaultInfo[cubesJson.cubeType],
      assetDisclaimer: cubesJson.disclaimer || defaultDisclaimer,
      cubeMinHyperTokenRatio: cubesJson.minHyperTokenRatioBP / 10000,
      cubeMaxHyperTokenRatio: cubesJson.maxHyperTokenRatioBP / 10000,
      cubeType: cubesJson.cubeType,
      title: cubesJson.description.replace(";", " "),
      guaranteedBP: cubesJson.guaranteedBP,
      referralBonusBP: cubesJson.referralBonusBP,
      assetDecimalsToShow: cubesJson.assetDecimalsToShow || 2,
      binanceSymbol: cubesJson.binanceSymbol,
      minimumUSDinvestment: cubesJson.minimumUSDinvestment,
      yearlyReturn: cubesJson.yearlyReturn,
      tradingPageTitle: this.cubeTypeStr(cubesJson.cubeType),
    });
    this.setState({
      windowSize: window.innerWidth >= 1024 ? 700 : window.innerWidth,
    });

    // this.fetchAreaChartData(cubesJson.cubeType);

    if (cubesJson.cubeType === 2 && cubesJson.binanceSymbol) {
      this.activatePriceStream();
    }

    // if (this.props.match.params.defaultTab === "details") {
    //this.setState({ defaultTab: 2 });
    // }

    this.setState({ cubeID: getCubeId() });
    hotjar.initialize("3117947", 6);
  }

  componentDidUpdate(prevProps, prevState) {
    /*
        if (this.state.areaChartDuration !== prevState.areaChartDuration) {
            this.fetchAreaChartData(this.state.cubeType);
        }
        */
    if (
      this.state.cubeType === 2 &&
      (this.state.racePrice !== prevState.racePrice ||
        this.state.cubeTargetPrice !== prevState.cubeTargetPrice)
    ) {
      if (prevState.racePrice === 0) {
        this.myAssetDetail();
      } else {
        this.setProfit(this.state.InvestmentDetails);
      }
    }
    if (this.state.priceStreamStatus === -1) {
      this.activatePriceStream();
    }
    let now = new Date();
    this.setState({
      investEnabled: now.getTime() / 1000 < this.state.cubeExpirationTimestamp,
    });
    if (this.state.cubeType === 0) {
    }
  }

  componentWillUnmount() {
    // clearInterval(this.interval);
    clearInterval(this.timer);
    this.setState({ priceStreamStatus: -2 });
    if (this.priceStream) {
      // this.priceStream.close()
      this.priceStream.onclose = () => {};
      this.priceStream.onmessage = () => {};
      this.priceStream.onerror = () => {};
    }
  }

  tick() {
    if (this.state.secondsToNextRefresh > 0) {
      this.setState({
        secondsToNextRefresh: this.state.secondsToNextRefresh - 1,
      });
    } else {
      this.refreshNow();
    }
  }

  refreshNow = () => {
    this.setState({ secondsToNextRefresh: 60 });
    this.myAssetDetail();
  };

  activatePriceStream = async () => {
    if (
      !this.state.binanceSymbol ||
      this.state.priceStreamStatus === 0 ||
      this.state.priceStreamStatus === -2
    )
      return;
    this.setState({ priceStreamStatus: 0 });
    this.priceStream = new WebSocket(
      "wss://dstream.binance.com/ws/" + this.state.binanceSymbol + "@markPrice"
    );
    this.priceStream.onopen = (e) => {
      // console.log("[open] Connection established");
      this.setState({ priceStreamStatus: 1 });
    };
    this.priceStream.onmessage = (event) => {
      // console.log(`[message] Data received from server: ${event.data}`);
      let data = JSON.parse(event.data);
      this.setState({ racePrice: parseFloat(data.p || 0) });
    };
    this.priceStream.onclose = (event) => {
      if (event.wasClean) {
        console.log(
          `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
        );
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        // console.log('[close] Connection died');
        this.setState({ priceStreamStatus: -1 });
      }
    };
    this.priceStream.onerror = (error) => {
      // console.log(`[error] ${error.message}`);
      this.setState({ priceStreamStatus: -1 });
    };
  };

  selectAreaDuration = (event) => {
    if (event && event.target && event.target.id) {
      this.setState({ areaChartDuration: event.target.id });
    }
  };

  fetchAreaChartData = (c) => {};

  refreshChart = (id, data) => {
    var options = {
      xaxis: {
        type: "category",
      },
      chart: {
        height: 380,
        width: "100%",
        type: "line",
      },
      tooltip: {
        shared: false,
        intersect: true,
      },
      markers: {
        size: 5,
      },
      series: [
        {
          name: "Series 1",
          data: data,
        },
      ],
    };
    var chart = new ApexCharts(document.querySelector(id), options);
    chart.render();
  };

  myAssetDetail = async () => {
    const { racePrice, cubeTargetPrice, cubeStopPrice, yearlyReturn } =
      this.state;
    let cubeType = this.state.cubeType;

    let cubeID = getCubeId();

    if (window.ethereum && this.state.walletAddress) {
      this.setState({ walletConnected: true });
      let web3 = await new Web3(window.ethereum);
      var account = await web3.currentProvider.selectedAddress;
      let abi = await config.FundManagerABIs[cubeType];
      var FundManagerContract = await new web3.eth.Contract(
        abi,
        config.FundManagerContracts[cubeType]
      );

      var HyperTokenContract = await new web3.eth.Contract(
        config.HyperTokenABI,
        config.HyperTokenContract
      );
      let cubeHyperTokenBalanceWei = await HyperTokenContract.methods
        .balanceOf(account)
        .call();
      var hyperTokenDecimals = await HyperTokenContract.methods
        .decimals()
        .call();
      let cubeHyperTokenBalanceGet =
        cubeHyperTokenBalanceWei / 10 ** hyperTokenDecimals;
      var cubeHyperTokenAllowance = await HyperTokenContract.methods
        .allowance(account, config.FundManagerContracts[cubeType])
        .call();
      var cubeHyperTokenAddress = config.HyperTokenContract;

      var getActualProfit = {
        assetPriceUSD: 0,
        hyperTokenPrice: 0,
        assetInvestment: 0,
        assetProfit: 0,
        assetLoss: 0,
        hyperTokenInvestment: 0,
        hyperTokenProfitBase: 0,
        hyperTokenProfitBonus: 0,
        hyperTokenInvestmentUSD: 0,
        estimatedAssetProfit: 0,
        estimatedHyperTokenProfit: 0,
        userElapsed: 0,
        secondsToExpiration: 0,
        profitDetail: {
          assetProfit: 0,
          estimatedAssetProfit: 0,
          estimatedHyperTokenProfit: 0,
          estimatedUnlockedAssetProfit: 0,
          estimatedUnlockedHyperTokenProfitBase: 0,
          estimatedUnlockedHyperTokenProfitBonus: 0,
          hyperTokenProfitBase: 0,
          hyperTokenProfitBonus: 0,
          unlockedAssetProfit: 0,
          unlockedHyperTokenProfitBase: 0,
          unlockedHyperTokenProfitBonus: 0,
        },
      };
      var canWithdraw = await FundManagerContract.methods
        .canWithdraw(cubeID, account)
        .call();
      var fundsAvailable = await FundManagerContract.methods
        .fundsAvailable(cubeID, account)
        .call();
      var cubeInfo = await FundManagerContract.methods.cubeInfo(cubeID).call();
      var userInfo = await FundManagerContract.methods
        .userInfo(cubeID, account)
        .call();

      var cubeAssetAddress = cubeInfo.asset;
      let abiCube = await config.HyperTokenABI;
      var cubeTokenContract = await new web3.eth.Contract(
        abiCube,
        cubeAssetAddress
      );
      let cubeAssetBalanceWei = await cubeTokenContract.methods
        .balanceOf(account)
        .call();
      var smartContractAssetBalance = await cubeTokenContract.methods
        .balanceOf(config.FundManagerContracts[cubeType])
        .call();
      smartContractAssetBalance =
        parseInt(smartContractAssetBalance || 0) / 1e18;
      var decimals = await cubeTokenContract.methods.decimals().call();
      var cubeName = await cubeTokenContract.methods.symbol().call();
      let cubeAssetBalanceGet = (cubeAssetBalanceWei - 1e6) / 10 ** decimals;
      if (cubeAssetBalanceGet < 0) {
        cubeAssetBalanceGet = 0;
      }
      var cubeAssetAllowance = await cubeTokenContract.methods
        .allowance(account, config.FundManagerContracts[cubeType])
        .call();
      var userInfoAmount =
        this.state.cubeType === 0 ? userInfo.shares : userInfo.assetAmount;
      let expirationDate = new Date(parseInt(cubeInfo.times.expiration) * 1000);
      getActualProfit = await FundManagerContract.methods
        .getActualProfit(cubeID, account)
        .call();

      this.setState({
        userInfoAmount: userInfoAmount,
        cubeAssetBalanceGet: cubeAssetBalanceGet,
        cubeAssetBalanceWei: cubeAssetBalanceWei,
        cubeAssetAllowance: cubeAssetAllowance,
        cubeAssetAddress: cubeAssetAddress,
        cubeAssetDecimals: parseInt(decimals),
        cubeName: cubeName,
        cubeDuration: cubeInfo.times.expiration - cubeInfo.times.start,
        cubeExpirationTimestamp: cubeInfo.times.expiration,
        cubeExpirationDate: expirationDate,
        cubeHyperTokenBalanceGet: cubeHyperTokenBalanceGet,
        cubeHyperTokenBalanceWei: cubeHyperTokenBalanceWei,
        cubeHyperTokenAllowance: cubeHyperTokenAllowance,
        cubeHyperTokenAddress: cubeHyperTokenAddress,
        cubeHyperTokenDecimals: parseInt(hyperTokenDecimals),
        referralUsed: userInfo.referralUsed,
        cubeStopPrice: parseInt(cubeInfo.stopPrice || 0) / 10 ** decimals,
        cubeTargetPrice: parseInt(cubeInfo.targetPrice || 0) / 10 ** decimals,
        cubeBorderPrice: parseInt(cubeInfo.borderPrice || 0) / 10 ** decimals,
        cubeEnabled: cubeInfo.enabled,
        cubeLastPrice: parseInt(cubeInfo.lastAssetPrice || 0) / 1e18,
        smartContractAssetBalance: smartContractAssetBalance,
      });

      let assetPriceUSD =
        cubeType === 2
          ? this.state.racePrice
          : parseInt(getActualProfit.assetPriceUSD) / 10 ** decimals;
      let hyperTokenPrice =
        parseInt(getActualProfit.hyperTokenPrice) / 10 ** decimals;
      let UnlockedAssetAmount = userInfo.unlockedValues
        ? parseInt(userInfo.unlockedValues.assetAmount) / 10 ** decimals
        : 0;
      let EarlyUnlockedAssetAmount =
        parseInt(userInfo.earlyUnlockedValues.assetAmount) / 10 ** decimals;
      let UnlockedHyperTokenAmount = userInfo.unlockedValues
        ? parseInt(userInfo.unlockedValues.hyperTokenAmount) /
          10 ** hyperTokenDecimals
        : 0;
      let EarlyUnlockedHyperTokenAmount =
        parseInt(userInfo.earlyUnlockedValues.hyperTokenAmount) /
        10 ** hyperTokenDecimals;
      let UnlockedAssetProfit = userInfo.unlockedValues
        ? (parseInt(userInfo.unlockedValues.assetProfit) -
            parseInt(userInfo.unlockedValues.assetLoss || 0)) /
          10 ** decimals
        : 0;
      let EarlyUnlockedAssetProfit =
        (parseInt(userInfo.earlyUnlockedValues.assetProfit) -
          parseInt(userInfo.earlyUnlockedValues.assetLoss || 0)) /
        10 ** decimals;
      let UnlockedHyperTokenProfitBase = userInfo.unlockedValues
        ? parseInt(userInfo.unlockedValues.hyperTokenProfitBase) /
          10 ** decimals
        : 0;
      let UnlockedHyperTokenProfitBonus = userInfo.unlockedValues
        ? parseInt(userInfo.unlockedValues.hyperTokenProfitBonus) /
          10 ** decimals
        : 0;
      let EarlyUnlockedHyperTokenProfitBase =
        parseInt(userInfo.earlyUnlockedValues.hyperTokenProfitBase) /
        10 ** decimals;
      let EarlyUnlockedHyperTokenProfitBonus =
        parseInt(userInfo.earlyUnlockedValues.hyperTokenProfitBonus) /
        10 ** decimals;
      let isHyper =
        parseInt(getActualProfit.hyperTokenInvestment) +
          parseInt(
            userInfo.unlockedValues
              ? userInfo.unlockedValues.hyperTokenAmount
              : 0
          ) !==
        0;
      let hyperBonusBP = isHyper ? userInfo.hyperBonusBP : 0;
      let hyperTokenYearlyReturnBP = isHyper
        ? cubeInfo.fees.hyperTokenYearlyReturnBP
        : 0;
      let EstimatedProfitInAsset =
        (parseInt(getActualProfit.profitDetail.estimatedAssetProfit) +
          parseInt(getActualProfit.profitDetail.estimatedUnlockedAssetProfit)) /
        10 ** decimals;
      let EstimatedProfitInHyperToken =
        (parseInt(getActualProfit.profitDetail.estimatedHyperTokenProfit) +
          parseInt(
            getActualProfit.profitDetail.estimatedUnlockedHyperTokenProfitBase
          ) +
          parseInt(
            getActualProfit.profitDetail.estimatedUnlockedHyperTokenProfitBonus
          )) /
        10 ** hyperTokenDecimals;
      let AssetProfit =
        (parseInt(getActualProfit.profitDetail.assetProfit || 0) -
          parseInt(getActualProfit.profitDetail.assetLoss || 0) +
          parseInt(getActualProfit.profitDetail.unlockedAssetProfit || 0)) /
        10 ** decimals;
      let HyperTokenProfitBase =
        (parseInt(getActualProfit.profitDetail.hyperTokenProfitBase) +
          parseInt(getActualProfit.profitDetail.unlockedHyperTokenProfitBase)) /
        10 ** hyperTokenDecimals;
      let HyperTokenProfitBonus =
        (parseInt(getActualProfit.profitDetail.hyperTokenProfitBonus) +
          parseInt(
            getActualProfit.profitDetail.unlockedHyperTokenProfitBonus
          )) /
        10 ** hyperTokenDecimals;
      let HyperTokenProfitTot = HyperTokenProfitBase + HyperTokenProfitBonus;
      if (UnlockedAssetProfit === 0 && cubeType === 1) {
        UnlockedAssetProfit =
          parseInt(getActualProfit.profitDetail.estimatedUnlockedAssetProfit) /
          10 ** decimals;
      }
      let EquityInAsset =
        (parseInt(getActualProfit.assetInvestment) +
          parseInt(getActualProfit.profitDetail.assetProfit) -
          parseInt(getActualProfit.profitDetail.assetLoss || 0)) /
          10 ** decimals +
        EarlyUnlockedAssetAmount +
        EarlyUnlockedAssetProfit +
        UnlockedAssetProfit; // + UnlockedAssetAmount + UnlockedAssetProfit
      let EquityInHyp =
        (parseInt(getActualProfit.hyperTokenInvestment) +
          parseInt(getActualProfit.profitDetail.hyperTokenProfitBase) +
          parseInt(getActualProfit.profitDetail.hyperTokenProfitBonus)) /
          10 ** decimals +
        UnlockedHyperTokenAmount +
        EarlyUnlockedHyperTokenAmount +
        UnlockedHyperTokenProfitBase +
        UnlockedHyperTokenProfitBonus +
        EarlyUnlockedHyperTokenProfitBase +
        EarlyUnlockedHyperTokenProfitBonus;
      let EquityInAssetUSD = EquityInAsset * assetPriceUSD;
      let EquityInHypUSD = EquityInHyp * hyperTokenPrice;
      var InvestedAmountinAsset =
        parseInt(userInfo.assetAmount || userInfo.amount) / 10 ** decimals; //  + parseInt(userInfo.pendingAssetAmount || 0))/(10**decimals)
      let pendingAssetAmount =
        parseInt(userInfo.pendingAssetAmount || 0) / 10 ** decimals;
      if (cubeType === 2) {
        InvestedAmountinAsset = InvestedAmountinAsset - pendingAssetAmount;
      }
      let sharePrice = parseInt(cubeInfo.sharePrice || 0) / 10 ** decimals;
      let avgSharePrice = parseInt(userInfo.price || 0) / 10 ** decimals;
      let userElapsed = parseInt(getActualProfit.userElapsed);
      let secondsToExpiration = parseInt(getActualProfit.secondsToExpiration);
      var totalAssetRewardBP = 0;
      var totalHyperTokenRewardBP = 0;
      var estimatedTotalAPR = 0;
      var yearlyHyperTokenRewardBP = parseInt(hyperTokenYearlyReturnBP) / 100;
      if (cubeType === 0 && avgSharePrice !== 0) {
        totalAssetRewardBP = (sharePrice / avgSharePrice - 1) * 100;
        if (sharePrice > avgSharePrice) {
          totalAssetRewardBP *=
            1 - parseInt(cubeInfo.fees.performanceFeeBP) / 10000;
        }
        /*
                estimatedTotalAPR = totalAssetRewardBP / userElapsed * (365 * 24 * 3600) // (userElapsed + secondsToExpiration)
                */
        estimatedTotalAPR = parseInt(yearlyReturn) / 100;
        yearlyHyperTokenRewardBP = estimatedTotalAPR;
        totalHyperTokenRewardBP =
          (estimatedTotalAPR *
            (10000 +
              parseInt(userInfo.bonusRewardBP) +
              parseInt(userInfo.referralBonusBP))) /
          1e4;
      } else if (cubeType === 2 && racePrice && cubeTargetPrice) {
        totalAssetRewardBP =
          (((1 / racePrice - 1 / cubeTargetPrice) * cubeStopPrice) /
            (racePrice - cubeStopPrice)) *
          racePrice *
          InvestedAmountinAsset;
        totalHyperTokenRewardBP =
          (totalAssetRewardBP *
            (10000 +
              parseInt(userInfo.bonusRewardBP) +
              parseInt(userInfo.referralBonusBP))) /
          1e4;
      } else {
        totalAssetRewardBP =
          (parseInt(cubeInfo.fees.yearlyReturnBP) + parseInt(hyperBonusBP)) /
          100;
        totalHyperTokenRewardBP =
          (parseInt(hyperTokenYearlyReturnBP) *
            (10000 +
              parseInt(userInfo.bonusRewardBP) +
              parseInt(userInfo.referralBonusBP))) /
          1e6;
      }
      let myArray = {
        InvestedAmountinAsset: InvestedAmountinAsset,
        InvestedAmountinAssetUSD: InvestedAmountinAsset * assetPriceUSD,
        ProfitInAsset: AssetProfit,
        ProfitInAssetUSD: AssetProfit * assetPriceUSD,
        LossInAsset:
          parseInt(getActualProfit.profitDetail.assetLoss || 0) /
          10 ** decimals,
        LossInAssetUSD:
          (parseInt(getActualProfit.profitDetail.assetLoss || 0) /
            10 ** decimals) *
          assetPriceUSD,
        HyperTokenInvestment:
          parseInt(getActualProfit.hyperTokenInvestment) /
          10 ** hyperTokenDecimals,
        HyperTokenInvestmentUSD:
          parseInt(getActualProfit.hyperTokenInvestmentUSD) / 10 ** 18,
        HyperTokenProfitBase: HyperTokenProfitBase,
        HyperTokenProfitBonus: HyperTokenProfitBonus,
        HyperTokenProfitTot: HyperTokenProfitTot,
        EquityInAsset: EquityInAsset,
        EquityInAssetUSD: EquityInAssetUSD,
        EquityInHyp: EquityInHyp,
        EquityInHypUSD: EquityInHypUSD,
        EstimatedProfitInAsset: EstimatedProfitInAsset,
        EstimatedProfitInAssetUSD: EstimatedProfitInAsset * assetPriceUSD,
        EstimatedProfitInHyperToken: EstimatedProfitInHyperToken,
        userElapsed: userElapsed,
        secondsToExpiration: secondsToExpiration,
        assetPriceUSD: assetPriceUSD,
        HyperTokenPrice: hyperTokenPrice,
        canWithdraw: canWithdraw, //  && (UnlockedAssetAmount !== 0 || EarlyUnlockedAssetAmount !== 0),
        fundsAvailable: fundsAvailable,
        fundsAvailableOnSmartContract:
          smartContractAssetBalance >
          UnlockedAssetAmount +
            UnlockedAssetProfit +
            EarlyUnlockedAssetAmount +
            EarlyUnlockedAssetProfit,
        totalEquityValue: EquityInAssetUSD + EquityInHypUSD,
        yearlyRewardBP: parseInt(cubeInfo.fees.yearlyReturnBP) / 100,
        yearlyHyperTokenRewardBP: yearlyHyperTokenRewardBP,
        hyperBonusBP: parseInt(hyperBonusBP) / 100,
        bonusRewardBP: parseInt(userInfo.bonusRewardBP) / 100,
        referralBonusBP: parseInt(userInfo.referralBonusBP) / 100,
        AssetUnlockedAmount: UnlockedAssetAmount, //.assetUnlockedAmount,
        HyperTokenUnlockedAmount: UnlockedHyperTokenAmount,
        AssetEarlyUnlockedAmount: EarlyUnlockedAssetAmount,
        UnlockedAssetProfit: UnlockedAssetProfit,
        UnlockedHyperTokenProfitBase: UnlockedHyperTokenProfitBase,
        UnlockedHyperTokenProfitBonus: UnlockedHyperTokenProfitBonus,
        EarlyUnlockedAssetProfit: EarlyUnlockedAssetProfit,
        EarlyUnlockedHyperTokenProfitBase: EarlyUnlockedHyperTokenProfitBase,
        EarlyUnlockedHyperTokenProfitBonus: EarlyUnlockedHyperTokenProfitBonus,
        EarlyUnlockedHyperTokenProfit:
          EarlyUnlockedHyperTokenProfitBase +
          EarlyUnlockedHyperTokenProfitBonus,
        HyperTokenEarlyUnlockedAmount: EarlyUnlockedHyperTokenAmount,
        totalAssetRewardBP: totalAssetRewardBP,
        totalHyperTokenRewardBP: totalHyperTokenRewardBP,
        TotalUnlockedAssetProfit:
          UnlockedAssetProfit + EarlyUnlockedAssetProfit,
        TotalUnlockedHyperTokenProfitBase:
          UnlockedHyperTokenProfitBase + EarlyUnlockedHyperTokenProfitBase,
        TotalUnlockedHyperTokenProfitBonus:
          UnlockedHyperTokenProfitBonus + EarlyUnlockedHyperTokenProfitBonus,
        TotalUnlockedHyperTokenProfit:
          UnlockedHyperTokenProfitBase +
          EarlyUnlockedHyperTokenProfitBase +
          UnlockedHyperTokenProfitBonus +
          EarlyUnlockedHyperTokenProfitBonus,
        avgEntryPrice: parseInt(userInfo.avgEntryPrice || 0) / 1e18,
        cumulativePosition: parseInt(userInfo.cumulativePosition || 0) / 1e18,
        sharePrice: sharePrice,
        pendingAssetAmount: pendingAssetAmount,
        estimatedTotalAPR: estimatedTotalAPR,
      };
      if (cubeType === 2) {
        this.setProfit(myArray);
      }
      this.setState({
        InvestmentDetails: myArray,
        earlyUnlockBtn: +myArray.totalEquityValue !== 0,
        UnlockBtn: +myArray.totalEquityValue !== 0,
      });
    } else {
      this.setState({ walletConnected: false });
    }
    this.setState({ loading: false });
  };

  setProfit = (item) => {
    let assetPriceUSD =
      this.state.cubeEnabled && this.state.investEnabled
        ? this.state.racePrice
        : this.state.cubeLastPrice;
    if (assetPriceUSD !== 0) {
      let direction = Math.sign(
        this.state.cubeTargetPrice - this.state.cubeStopPrice
      );
      let assetProfit =
        (1 / item.avgEntryPrice - 1 / assetPriceUSD) *
        item.cumulativePosition *
        direction;
      let profitUSD = assetProfit * assetPriceUSD;
      // console.log(item.avgEntryPrice, assetPriceUSD, item.cumulativePosition, assetProfit, profitUSD)
      item.ProfitInAsset = assetProfit;
      item.ProfitInAssetUSD = profitUSD;
      item.totalAssetRewardBP =
        (assetProfit / item.InvestedAmountinAsset) * 100;
      item.totalHyperTokenRewardBP =
        (item.totalAssetRewardBP *
          (10000 +
            parseInt(item.bonusRewardBP) +
            parseInt(item.referralBonusBP))) /
        1e4;
      if (assetProfit > 0) {
        let profitPerc = assetProfit / item.InvestedAmountinAssetUSD;
        let hypProfitBase =
          (item.HyperTokenInvestmentUSD * profitPerc) / item.HyperTokenPrice;
        let hypProfitBonus =
          (hypProfitBase * (item.bonusRewardBP + item.referralBonusBP)) / 100;
        item.HyperTokenProfitBase = hypProfitBase;
        item.HyperTokenProfitBonus = hypProfitBonus;
        item.HyperTokenProfitTot = hypProfitBase + hypProfitBonus;
      }
    }
  };

  unlockedAmountAvailable = () => {
    return (
      this.state.InvestmentDetails.AssetUnlockedAmount +
      this.state.InvestmentDetails.HyperTokenUnlockedAmount +
      this.state.InvestmentDetails.AssetEarlyUnlockedAmount +
      this.state.HyperTokenEarlyUnlockedAmount
    );
  };

  cubeTypeStr = (c) => {
    if (c === 0) {
      return "Algo Trading Cube";
    } else if (c === 1) {
      return "Fixed Income Cube";
    } else if (c === 2) {
      return "Race Trading Cube";
    } else {
      return "Type" + c + " Cube";
    }
  };

  setAlertOpen = (state) => {
    this.setState({ openAlert: state });
  };

  buyCrypto = () => {
    let transak = new transakSDK({
      apiKey: "dac64679-f08e-4b68-9cbe-3b15c5599b25", // Your API Key
      environment: "STAGING", // STAGING/PRODUCTION
      hostURL: window.location.origin,
      widgetHeight: "650px",
      widgetWidth: "500px",
      // Examples of some of the customization parameters you can pass
      defaultCryptoCurrency: this.state.cubeName, // Example 'ETH'
      walletAddress: this.props.walletAddress, // Your customer's wallet address
      themeColor: "72056ab5", // App theme color
      fiatCurrency: "", // If you want to limit fiat selection eg 'USD'
      email: "info@hyperdex.finance", // Your customer's email address
      redirectURL: window.location.origin,
      cryptoCurrency: this.state.cubeName,
      hideMenu: true,
      networks: "BSC",
    });

    transak.init();

    // To get all the events
    transak.on(transak.ALL_EVENTS, (data) => {
      console.log(data);
    });

    // This will trigger when the user marks payment is made.
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
      console.log(orderData);
      transak.close();
    });
  };

  render() {
    const {
      title,
      cubeType,
      cubeStopPrice,
      cubeTargetPrice,
      cubeBorderPrice,
      cubeExpirationDate,
      loading,
      defaultTab,
      walletConnected,
      cubeAssetAddress,
      cubeName,
      cubeAssetDecimals,
      secondsToNextRefresh,
      tradingPageTitle,
    } = this.state;

    const { t, registerToken } = this.props;
    return (
      <>
        <section className="page-header">
          <div className="page-header-bg"></div>

          <div className="page-header-shape-1"></div>
          <div className="page-header-shape-2"></div>
          <div className="page-header-shape-3"></div>

          <div className="container text-center">
            <div className="page-header__inner">
              <div className="row">
                <div className="col-xl-6 col-lg-6">
                  <div className="abs-center containerCube">
                    <div className="cubez cube1">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube2">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube3">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube4">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube5">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube6">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube7">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube8">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube9">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube10">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube11">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube12">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube13">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube14">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube15">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube16">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube17">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube18">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube19">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube20">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube21">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube22">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube23">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube24">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube25">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube26">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                    <div className="cubez cube27">
                      <div className="front"></div>
                      <div className="left"></div>
                      <div className="top"></div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <h2>{tradingPageTitle}</h2>
                  <hr />
                  <p></p>
                  <div className="col-lg-12 col-md-12 col-xs-12 text-center">
                    <div className="row m-t-20">
                      <h3 style={{ textAlign: "center", color: "white" }}>
                        <b style={{ color: "#ffa0f8" }}>{title}</b>
                        {!loading && walletConnected && (
                          <>
                            <br />
                            Asset: {cubeName}
                          </>
                        )}
                      </h3>
                      {!loading && walletConnected && (
                        <>
                          <h5 style={{ textAlign: "center", color: "white" }}>
                            <Link
                              to="#"
                              onClick={() =>
                                registerToken(
                                  cubeAssetAddress,
                                  cubeName,
                                  cubeAssetDecimals
                                )
                              }
                            >
                              {t("Add to Metamask")}
                            </Link>
                            {/* &nbsp;|&nbsp;<Link to="#" onClick={() => this.buyCrypto()}>{t("Buy Crypto")}</Link> */}
                          </h5>
                          <h5 style={{ textAlign: "center", color: "gray" }}>
                            Expiration: {cubeExpirationDate.toGMTString()}
                            {cubeType === 2 ? (
                              <>
                                <br />
                                Stop Price: {cubeStopPrice} &nbsp; Target Price:{" "}
                                {cubeTargetPrice} &nbsp; Border Price:{" "}
                                {cubeBorderPrice}
                              </>
                            ) : (
                              ""
                            )}
                          </h5>
                          <div className="col-md-12 m-t-1e">
                            <Link
                              to="#"
                              className={"thm-btn"}
                              style={{ width: "60%" }}
                              onClick={(e) => this.refreshNow()}
                            >
                              {secondsToNextRefresh === 0
                                ? t("Loading...")
                                : t("Auto refresh in ") + secondsToNextRefresh}
                              &nbsp;
                              <i className="fa-solid fa-arrows-rotate" />
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 text-center">
                <div id="chart2"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="hyperdex_cubes">
          <div className="container text-center"></div>
          <div className="container">
            <div className="hyperdex_cubes__tab-box tabs-box">
              <ul className="tab-buttons clearfix list-unstyled">
                <li
                  data-tab="#invest"
                  className={
                    "tab-btn" + (defaultTab === 0 ? " active-btn" : "")
                  }
                >
                  <span>{t("Invest")}</span>
                </li>
                <li
                  data-tab="#withdraw"
                  className={
                    "tab-btn" + (defaultTab === 1 ? " active-btn" : "")
                  }
                >
                  <span>{t("Withdraw")}</span>
                </li>
                <li
                  data-tab="#details"
                  className={
                    "tab-btn" + (defaultTab === 2 ? " active-btn" : "")
                  }
                >
                  <span>{t("Investment Details")}</span>
                </li>
                <li
                  data-tab="#info"
                  className={
                    "tab-btn" + (defaultTab === 3 ? " active-btn" : "")
                  }
                >
                  <span>{t("Information")}</span>
                </li>
              </ul>
              <div className="tabs-content">
                {/* tab */}
                <div
                  className={"tab" + (defaultTab === 0 ? " active-tab" : "")}
                  id="invest"
                >
                  {this.state.walletAddress || true ? (
                    <InvestForm
                      fullData={this.state}
                      size={this.state.windowSize}
                      account={this.state.walletAddress}
                      referralUsed={this.state.referralUsed}
                      alreadyInvestedUSD={
                        this.state.InvestmentDetails.InvestedAmountinAssetUSD
                      }
                      alreadyInvestedHypUSD={
                        this.state.InvestmentDetails.HyperTokenInvestmentUSD
                      }
                      alreadyUnlocked={
                        this.state.InvestmentDetails.AssetUnlockedAmount
                      }
                      assetDecimalsToShow={this.state.assetDecimalsToShow}
                      loading={this.state.loading}
                      cubeEnabled={this.state.cubeEnabled}
                      investEnabled={this.state.investEnabled}
                      canWithdraw={this.state.InvestmentDetails.canWithdraw}
                      fundsAvailable={
                        this.state.InvestmentDetails.fundsAvailable
                      }
                      walletConnected={this.state.walletConnected}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {/* tab */}
                <div
                  className={"tab" + (defaultTab === 2 ? " active-tab" : "")}
                  id="details"
                >
                  <div className="tabs-content__inner">
                    {walletConnected ? (
                      this.state.loading ? (
                        <div className="row text-center">
                          <h3 className="text-white">Loading data...</h3>
                        </div>
                      ) : this.state.InvestmentDetails.totalEquityValue ? (
                        <>
                          <div className="row">
                            <div className="col-xl-4 col-lg-4">
                              <h2
                                className="top"
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                ASSET
                              </h2>
                            </div>
                            <div className="col-xl-4 col-lg-4"></div>
                            <div className="col-xl-4 col-lg-4">
                              <h2
                                className="top"
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                HYP
                              </h2>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-xl-4 col-lg-4">
                              <h4
                                className="top"
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                {this.state.InvestmentDetails
                                  .InvestedAmountinAsset
                                  ? this.state.InvestmentDetails.InvestedAmountinAsset.toFixed(
                                      this.state.assetDecimalsToShow
                                    )
                                  : "0.00"}
                              </h4>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                              <h5
                                className="top"
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                {t("Invested Amount")}
                              </h5>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                              <h4
                                className="top"
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                {this.state.InvestmentDetails
                                  .HyperTokenInvestment
                                  ? this.state.InvestmentDetails.HyperTokenInvestment.toFixed(
                                      4
                                    )
                                  : "0.0000"}
                              </h4>
                            </div>
                          </div>

                          {cubeType === 0 && (
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                <h4
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {this.state.InvestmentDetails.shares
                                    ? this.state.InvestmentDetails.shares.toFixed(
                                        12
                                      )
                                    : "0.00"}
                                </h4>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h5
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {t("Available Shares")}
                                </h5>
                              </div>
                              <div className="col-xl-4 col-lg-4"></div>
                            </div>
                          )}

                          {(cubeType === 0 || cubeType === 2) &&
                            this.state.InvestmentDetails.pendingAssetAmount !==
                              0 && (
                              <div className="row">
                                <div className="col-xl-4 col-lg-4">
                                  <h4
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                    }}
                                  >
                                    {this.state.InvestmentDetails
                                      .pendingAssetAmount
                                      ? this.state.InvestmentDetails.pendingAssetAmount.toFixed(
                                          this.state.assetDecimalsToShow
                                        )
                                      : "0.00"}
                                  </h4>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                  <h5
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                    }}
                                  >
                                    {t("Pending Amount")}{" "}
                                    {cubeType == 0 && t("until 12pm")}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {this.state.InvestmentDetails.AssetUnlockedAmount !==
                            0 && (
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                <h4
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {this.state.InvestmentDetails
                                    .AssetUnlockedAmount
                                    ? this.state.InvestmentDetails.AssetUnlockedAmount.toFixed(
                                        this.state.assetDecimalsToShow
                                      )
                                    : "0.00"}
                                </h4>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h5
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {t("Unlocked Amount")}
                                </h5>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h4
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {this.state.InvestmentDetails
                                    .HyperTokenUnlockedAmount
                                    ? this.state.InvestmentDetails.HyperTokenUnlockedAmount.toFixed(
                                        4
                                      )
                                    : "0.0000"}
                                </h4>
                              </div>
                            </div>
                          )}
                          {this.state.InvestmentDetails
                            .AssetEarlyUnlockedAmount !== 0 && (
                            <>
                              <div className="row">
                                <div className="col-xl-4 col-lg-4">
                                  <h4
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                    }}
                                  >
                                    {this.state.InvestmentDetails
                                      .AssetEarlyUnlockedAmount
                                      ? this.state.InvestmentDetails.AssetEarlyUnlockedAmount.toFixed(
                                          this.state.assetDecimalsToShow
                                        )
                                      : "0.00"}
                                  </h4>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                  <h5
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                    }}
                                  >
                                    {t("Early Unlocked Amount")}
                                  </h5>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                  <h4
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                    }}
                                  >
                                    {this.state.InvestmentDetails
                                      .HyperTokenEarlyUnlockedAmount
                                      ? this.state.InvestmentDetails.HyperTokenEarlyUnlockedAmount.toFixed(
                                          4
                                        )
                                      : "0.0000"}
                                  </h4>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-xl-4 col-lg-4">
                                  <h4
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                    }}
                                  >
                                    {this.state.InvestmentDetails
                                      .EarlyUnlockedAssetProfit
                                      ? this.state.InvestmentDetails.EarlyUnlockedAssetProfit.toFixed(
                                          this.state.assetDecimalsToShow
                                        )
                                      : "0.00"}
                                  </h4>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                  <h5
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                    }}
                                  >
                                    {t("Early Unlocked Profit")}
                                  </h5>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                  <h4
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                    }}
                                  >
                                    {this.state.InvestmentDetails
                                      .EarlyUnlockedHyperTokenProfit
                                      ? this.state.InvestmentDetails.EarlyUnlockedHyperTokenProfit.toFixed(
                                          4
                                        )
                                      : "0.0000"}
                                  </h4>
                                  <h4
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                    }}
                                  >
                                    {this.state.InvestmentDetails
                                      .EarlyUnlockedHyperTokenProfitBase
                                      ? this.state.InvestmentDetails.EarlyUnlockedHyperTokenProfitBase.toFixed(
                                          4
                                        )
                                      : "0.0000"}{" "}
                                    base +&nbsp;
                                    {this.state.InvestmentDetails
                                      .EarlyUnlockedHyperTokenProfitBonus
                                      ? this.state.InvestmentDetails.EarlyUnlockedHyperTokenProfitBonus.toFixed(
                                          4
                                        )
                                      : "0.0000"}{" "}
                                    bonus
                                  </h4>
                                </div>
                              </div>
                            </>
                          )}
                          {cubeType === 1 && (
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                <h4
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {this.state.InvestmentDetails
                                    .totalAssetRewardBP
                                    ? this.state.InvestmentDetails.totalAssetRewardBP.toFixed(
                                        2
                                      )
                                    : "0.00"}{" "}
                                  %
                                </h4>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h5
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {t("Total APR")}
                                </h5>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h4
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {this.state.InvestmentDetails
                                    .totalHyperTokenRewardBP
                                    ? this.state.InvestmentDetails.totalHyperTokenRewardBP.toFixed(
                                        2
                                      )
                                    : "0.00"}{" "}
                                  %
                                </h4>
                              </div>
                            </div>
                          )}
                          {cubeType === 0 && (
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                <h4
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {this.state.InvestmentDetails
                                    .estimatedTotalAPR
                                    ? this.state.InvestmentDetails.estimatedTotalAPR.toFixed(
                                        2
                                      )
                                    : "0.00"}{" "}
                                  %
                                </h4>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h5
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {t("Expected Return")}
                                </h5>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h4
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {this.state.InvestmentDetails
                                    .totalHyperTokenRewardBP
                                    ? this.state.InvestmentDetails.totalHyperTokenRewardBP.toFixed(
                                        2
                                      )
                                    : "0.00"}{" "}
                                  %
                                </h4>
                              </div>
                            </div>
                          )}
                          {cubeType === 2 && (
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                <h4
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {(
                                    this.state.InvestmentDetails
                                      .totalAssetRewardBP || 0
                                  ).toFixed(2)}{" "}
                                  %
                                </h4>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h5
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {t("Current Return")}
                                </h5>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h4
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {(
                                    this.state.InvestmentDetails
                                      .totalHyperTokenRewardBP || 0
                                  ).toFixed(2)}{" "}
                                  %{" "}
                                  <span style={{ color: "gray" }}>
                                    of USD value
                                  </span>
                                  <div style={{ color: "gray" }}>including</div>
                                  {this.state.InvestmentDetails.bonusRewardBP
                                    ? this.state.InvestmentDetails.bonusRewardBP.toFixed(
                                        2
                                      )
                                    : "0.00"}{" "}
                                  % bonus
                                  <br />
                                  {this.state.InvestmentDetails.referralBonusBP
                                    ? this.state.InvestmentDetails.referralBonusBP.toFixed(
                                        2
                                      )
                                    : "0.00"}{" "}
                                  % referral
                                </h4>
                              </div>
                            </div>
                          )}
                          {cubeType !== 2 && (
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                {cubeType !== 0 && (
                                  <h4
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                    }}
                                  >
                                    {this.state.InvestmentDetails.yearlyRewardBP
                                      ? this.state.InvestmentDetails.yearlyRewardBP.toFixed(
                                          2
                                        )
                                      : "0.00"}{" "}
                                    % base
                                    {this.state.InvestmentDetails
                                      .hyperBonusBP !== 0 && (
                                      <>
                                        <br />
                                        {this.state.InvestmentDetails
                                          .hyperBonusBP
                                          ? this.state.InvestmentDetails.hyperBonusBP.toFixed(
                                              2
                                            )
                                          : "0.00"}{" "}
                                        % HyperCube
                                      </>
                                    )}
                                  </h4>
                                )}
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h5
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {cubeType !== 0
                                    ? t("Detailed APR")
                                    : t("Detailed HYP Apr")}
                                </h5>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h4
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {this.state.InvestmentDetails
                                    .yearlyHyperTokenRewardBP
                                    ? this.state.InvestmentDetails.yearlyHyperTokenRewardBP.toFixed(
                                        2
                                      )
                                    : "0.00"}{" "}
                                  % base
                                  <br />
                                  {this.state.InvestmentDetails.bonusRewardBP
                                    ? this.state.InvestmentDetails.bonusRewardBP.toFixed(
                                        2
                                      )
                                    : "0.00"}{" "}
                                  % bonus
                                  <br />
                                  {this.state.InvestmentDetails.referralBonusBP
                                    ? this.state.InvestmentDetails.referralBonusBP.toFixed(
                                        2
                                      )
                                    : "0.00"}{" "}
                                  % referral
                                </h4>
                              </div>
                            </div>
                          )}

                          <div className="row">
                            <div className="col-xl-4 col-lg-4">
                              <h4
                                className="top"
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                {this.state.InvestmentDetails.ProfitInAsset
                                  ? parseFloat(
                                      this.state.InvestmentDetails.ProfitInAsset
                                    ).toFixed(this.state.assetDecimalsToShow)
                                  : "0.00"}{" "}
                                {this.state.cubeName || ""}
                              </h4>
                              <h5
                                className="top"
                                style={{ textAlign: "center", color: "gray" }}
                              >
                                (
                                {this.state.InvestmentDetails.ProfitInAssetUSD
                                  ? parseFloat(
                                      this.state.InvestmentDetails
                                        .ProfitInAssetUSD
                                    ).toFixed(2)
                                  : "0.00"}{" "}
                                $)
                              </h5>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                              <h5
                                className="top"
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                {t("Net Current Profit")}
                              </h5>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                              <h4
                                className="top"
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                {this.state.InvestmentDetails
                                  .HyperTokenProfitTot
                                  ? this.state.InvestmentDetails.HyperTokenProfitTot.toFixed(
                                      4
                                    )
                                  : "0.0000"}
                              </h4>
                              <h4
                                className="top"
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                {this.state.InvestmentDetails
                                  .HyperTokenProfitBase
                                  ? this.state.InvestmentDetails.HyperTokenProfitBase.toFixed(
                                      4
                                    )
                                  : "0.0000"}{" "}
                                base +&nbsp;
                                {this.state.InvestmentDetails
                                  .HyperTokenProfitBonus
                                  ? this.state.InvestmentDetails.HyperTokenProfitBonus.toFixed(
                                      4
                                    )
                                  : "0.0000"}{" "}
                                bonus
                              </h4>
                            </div>
                          </div>
                          {cubeType === 0 && (
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                <h4
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {this.state.InvestmentDetails
                                    .totalAssetRewardBP
                                    ? parseFloat(
                                        this.state.InvestmentDetails
                                          .totalAssetRewardBP
                                      ).toFixed(2)
                                    : "0.00"}{" "}
                                  %
                                </h4>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h5
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {t("Net Current Profit Percentage")}
                                </h5>
                              </div>
                            </div>
                          )}
                          {cubeType === 2 && (
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                <h4
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {this.state.racePrice
                                    ? parseFloat(this.state.racePrice).toFixed(
                                        this.state.assetDecimalsToShow
                                      )
                                    : "0.00"}{" "}
                                  {t("Current")}
                                  <br />
                                  {this.state.InvestmentDetails.avgEntryPrice
                                    ? parseFloat(
                                        this.state.InvestmentDetails
                                          .avgEntryPrice
                                      ).toFixed(this.state.assetDecimalsToShow)
                                    : "0.00"}{" "}
                                  Avg Entry
                                </h4>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h5
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {t("Futures Price")}
                                </h5>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h4
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                ></h4>
                              </div>
                            </div>
                          )}
                          {cubeType === 1 && (
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                <h4
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {this.state.InvestmentDetails
                                    .EstimatedProfitInAsset
                                    ? parseFloat(
                                        this.state.InvestmentDetails
                                          .EstimatedProfitInAsset
                                      ).toFixed(this.state.assetDecimalsToShow)
                                    : "0.00"}
                                </h4>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h5
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {t("Estimated Total Profit")}
                                  {this.state.InvestmentDetails
                                    .secondsToExpiration ? (
                                    <div className="time-text">
                                      in{" "}
                                      {timeFormatter(
                                        this.state.InvestmentDetails
                                          .secondsToExpiration
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </h5>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h4
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {this.state.InvestmentDetails
                                    .EstimatedProfitInHyperToken
                                    ? parseFloat(
                                        this.state.InvestmentDetails
                                          .EstimatedProfitInHyperToken
                                      ).toFixed(4)
                                    : "0.0000"}
                                </h4>
                              </div>
                            </div>
                          )}
                          <hr />
                          <div className="row">
                            <div className="col-xl-4 col-lg-4">
                              <h2
                                className="top"
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                ASSET EQUITY
                                <br />
                                {parseFloat(
                                  this.state.InvestmentDetails.EquityInAsset ||
                                    0
                                ).toFixed(6)}{" "}
                                {this.state.cubeName || ""}
                                <br />
                                {parseFloat(
                                  this.state.InvestmentDetails
                                    .EquityInAssetUSD || 0
                                ).toFixed(2)}{" "}
                                $
                              </h2>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                              <h2
                                className="top"
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                HYP EQUITY
                                <br />
                                {parseFloat(
                                  this.state.InvestmentDetails.EquityInHyp || 0
                                ).toFixed(6)}{" "}
                                HYP
                                <br />
                                {parseFloat(
                                  this.state.InvestmentDetails.EquityInHypUSD ||
                                    0
                                ).toFixed(2)}{" "}
                                $
                              </h2>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                              <h2
                                className="top"
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                {t("TOTAL EQUITY")}
                                <br />
                                {t("Value")}
                                <br />
                                {parseFloat(
                                  this.state.InvestmentDetails
                                    .totalEquityValue || 0
                                ).toFixed(2)}{" "}
                                $
                              </h2>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="row text-center">
                          <h3 className="text-white">Zero Asset</h3>
                        </div>
                      )
                    ) : (
                      <div className="tabs-content__inner">
                        <div className="row text-center">
                          <h3 className="text-white">
                            Please connect Web3 wallet
                          </h3>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* tab */}
                <div
                  className={"tab" + (defaultTab === 1 ? " active-tab" : "")}
                  id="withdraw"
                >
                  {this.state.walletAddress || true ? (
                    <WithdrawForm
                      fullData={this.state}
                      investEnabled={this.state.investEnabled}
                      expirationTimestamp={this.state.cubeExpirationTimestamp}
                      size={this.state.windowSize}
                      account={this.state.walletAddress}
                      referralUsed={this.state.referralUsed}
                      alreadyInvestedUSD={
                        this.state.InvestmentDetails.InvestedAmountinAssetUSD
                      }
                      alreadyInvestedHypUSD={
                        this.state.InvestmentDetails.HyperTokenInvestmentUSD
                      }
                      alreadyUnlocked={
                        this.state.InvestmentDetails.AssetUnlockedAmount
                      }
                      assetDecimalsToShow={this.state.assetDecimalsToShow}
                      canWithdraw={this.state.InvestmentDetails.canWithdraw}
                      fundsAvailable={
                        this.state.InvestmentDetails.fundsAvailable
                      }
                      pendingWithdrawal={this.state.pendigWithdrawal}
                      loading={this.state.loading}
                      walletConnected={this.state.walletConnected}
                      userInfoAmount={this.state.userInfoAmount}
                      cubeEnabled={this.state.cubeEnabled}
                      sharePrice={this.state.InvestmentDetails.sharePrice}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {/* tab */}
                <div
                  className={"tab" + (defaultTab === 3 ? " active-tab" : "")}
                  id="info"
                >
                  <div className="tabs-content__inner">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="tabs-content__left">
                          <ul className="list-unstyled tabs-content__points text-center">
                            <li>
                              <div className="text">
                                <h2
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {t("DISCLAIMER")}
                                </h2>
                              </div>
                            </li>
                            <li>
                              <div className="text">
                                <p>{this.state.assetDisclaimer}</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <ul className="list-unstyled tabs-content__points text-center">
                          <li>
                            <div className="text">
                              <h2
                                className="top"
                                style={{ textAlign: "center", color: "white" }}
                              >
                                {t("Cube Info")}
                              </h2>
                            </div>
                          </li>
                          <li>
                            <div className="text">
                              <p>{this.state.assetInfo}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

//export default connect(mapStateToProps)(withRouter(withTranslation()(Trading)));
export default withTranslation()(Trading);
