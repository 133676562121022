import React, { useState, useEffect } from 'react';
import Web3 from 'web3';

import meterImg from '../../assets/images/icon_2.svg'
// import meterImgOverlay from '../../assets/images/icon_2_overlay.svg'
import { withTranslation } from 'react-i18next'

function BonusMeter(props) {
    const { t } = props
    const [width, setWidth] = useState(0)
    const { investAmountUSD, size, account, referralBonusBP, referralCode, setReferralCode, referralUsed, alreadyInvestedUSD } = props
    // const [radius, setRadius] = useState(0)
    const [reward, setReward] = useState(0)
    const [totalInvestedUSD, setTotalInvestedUSD] = useState(0)
    const [referralError, setReferralError] = useState(false)

    useEffect(() => {
        // let localSize = 999 // size
        let totalInvestedUSD = parseFloat(investAmountUSD) + alreadyInvestedUSD
        setTotalInvestedUSD(totalInvestedUSD)
        var px = 0
        let stepPerc = 0.167 // 0.163
        // let zeroStep = 50 // Math.max(localSize * 0.0625, 25)
        if (totalInvestedUSD < 100) {
            px = stepPerc * totalInvestedUSD / 100
            setReward(0)
        } else if (totalInvestedUSD < 500) {
            px = stepPerc + stepPerc * (totalInvestedUSD - 100) / 400
            setReward(2.5)
        } else if (totalInvestedUSD < 1000) {
            px = stepPerc * 2 + stepPerc * (totalInvestedUSD - 500) / 500
            setReward(5)
        } else if (totalInvestedUSD < 10000) {
            px = stepPerc * 3 + stepPerc * (totalInvestedUSD - 1000) / 9000
            setReward(7.5)
        } else if (totalInvestedUSD >= 10000) {
            px = stepPerc * 4 + stepPerc * (totalInvestedUSD - 10000) / 20000
            setReward(10)
        }
        px += 0.055
        px = Math.min(px, 0.9)
        // px *= localSize // size
        // px = Math.max(zeroStep, px + zeroStep)
        /*
        if (px <= 50) {
            setRadius(0)
        } else {
            setRadius(25)
        }
        */
        setWidth(100 - px * 100)
    }, [investAmountUSD, alreadyInvestedUSD, size]);

    const HandleReferralChange = async (e) => {
        if (e.target.value === '') {
            setReferralError(false)
        } else if (Web3.utils.isAddress(e.target.value)) {
            setReferralError(false)
        } else {
            setReferralError(true)
        }
        setReferralCode(e.target.value)
    }

    return (
        <>
        <h3 className="m-b-1e" style={{ textAlign: 'center', color: 'white' }}>{t("BONUS AND REWARDS")}</h3>
        <div className="row">
            <div className="col-md-2 text-center"></div>    
            <div className="col-md-2 text-center">
                <div className="color-cube mx-auto">
                    <div className="cube-face cube-front"></div>
                    <div className="cube-face cube-back"></div>
                    <div className="cube-face cube-bottom"></div>
                    <div className="cube-face cube-top"></div>
                    <div className="cube-face cube-left"></div>
                    <div className="cube-face cube-right"></div>
                </div>
                            
            <h3 className="m-t-1e m-b-1e text-white">+2.5%</h3>
        </div>
        <div className="col-md-2 text-center">
            <div className="color-cube2 mx-auto">
                <div className="cube-face2 cube-front2"></div>
                <div className="cube-face2 cube-back2"></div>
                <div className="cube-face2 cube-bottom2"></div>
                <div className="cube-face2 cube-top2"></div>
                <div className="cube-face2 cube-left2"></div>
                <div className="cube-face2 cube-right2"></div>
            </div>
            <h3 className="m-t-1e m-b-1e text-white">+5%</h3>
        </div>
        <div className="col-md-2 text-center">
            <div className="color-cube3 mx-auto">
                <div className="cube-face3 cube-front3"></div>
                <div className="cube-face3 cube-back3"></div>
                <div className="cube-face3 cube-bottom3"></div>
                <div className="cube-face3 cube-top3"></div>
                <div className="cube-face3 cube-left3"></div>
                <div className="cube-face3 cube-right3"></div>
            </div>
            <h3 className="m-t-1e m-b-1e text-white">+7.5%</h3>
        </div>
        <div className="col-md-2 text-center">
            <div className="color-cube4 mx-auto">
                <div className="cube-face4 cube-front4"></div>
                <div className="cube-face4 cube-back4"></div>
                <div className="cube-face4 cube-bottom4"></div>
                <div className="cube-face4 cube-top4"></div>
                <div className="cube-face4 cube-left4"></div>
                <div className="cube-face4 cube-right4"></div>
            </div>
            <h3 className="m-t-1e m-b-1e text-white">+10%</h3>
        </div>
        <div className="mx-auto">
            <img className="w-80" 
                style={{ background: 'linear-gradient(270deg, transparent ' + width + '%, rgb(136, 19, 131) ' + width + '%)' }} 
                src={meterImg} alt="" />
        </div>
        <h4 className="color-1">{t("Total invested USD value:")} { (totalInvestedUSD ? totalInvestedUSD.toFixed(2) : '0.00') }$ <br/>
            { reward ?
                t("YOU GET ADDITIONAL HYPER TOKENs!") + ' (' + reward + '%)'
            :
                t("Invest more to get a reward!")
            }    
        </h4>             
    </div>
    <div className="col-md-7 mx-auto m-t-20">
        <h3 className="text-white"> &nbsp;</h3>
        { referralUsed && false ?
            <p>{t("You already used a referral code on this Cube")}</p>
        :
            <>
            <label>{t("Add a referral code to get more reward!")}</label>
            <div className="comment-form__input-box">
                <input type="text" style={{ height: '38px' }} className="text-center form-control" placeholder={t("enter your referral's code...")} name="input_amount" 
                    onChange={e => HandleReferralChange(e)} value={referralCode} />
            </div>
            <label>
                { referralError ?
                    t("Please enter a correct referral code")
                :
                    (referralCode ? 
                        (referralCode.toUpperCase() === account.toUpperCase() ?
                            t("Referral cannot be your own account")
                        :
                            t("YOU GET AN ADDITIONAL HYP REWARD!") + ' (' + (referralBonusBP / 100) + '%)'
                        )
                    :
                        ''
                    )
                }
                </label>
            </>
        }
    </div>
    </>
    )
}

export default withTranslation()(BonusMeter);